import React from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorGraphicIllustration from '../../assets/images/image_error_graphic_illustration.png';
import { paths } from '../../routes';
import Navbar from '../../components/Navbar/Navbar';
import Button from '../../components/Button/Button';
import MarkdownHTML from '../../components/MarkdownHTML/MarkdownHTML';
import { env } from '../../utils';

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="relative min-h-screen h-auto overflow-auto bg-white flex flex-col">
      <Navbar logout={() => false} page={0} />
      <main className="pt-10 flex-auto h-auto">
        <div className="relative pt-8 tablet:pt-14 pb-14 flex flex-col justify-center items-center">
          <h3 className="text-[#FF9B5B] text-lg font-medium mb-2">
            Hmm. Are we lost? 🤔
          </h3>
          <span>
            <pre className="inline-flex">
              {`Looks like you're lost. Don't worry lets take you home.`}
            </pre>
          </span>

          <img
            src={ErrorGraphicIllustration}
            alt="error graphic"
            className="w-[250px] tablet:w-[350px]"
          />

          <Button
            label="Go Home"
            onClick={() => navigate(paths.HOME_URL_PATH)}
            className="w-[225px] mt-10"
          />

          <MarkdownHTML
            className="text-river-black text-xs mt-6 text-center"
            content={`If this persists, please reach out to our [support team.](${env(
              'RIVER_SUPPORT_URL',
            )})`}
          />
        </div>
      </main>
    </div>
  );
}

export default NotFound;
