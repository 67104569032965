/* eslint-disable react/button-has-type */
import React from 'react';
import Icon from '../Icon/Icon';

type ButtonProps = {
  id?: string;
  label: string;
  type?: 'button' | 'submit' | 'reset';
  onClick: (value: any) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
};

function Button({
  id,
  label,
  type,
  className,
  disabled,
  loading,
  onClick,
}: ButtonProps) {
  return (
    <button
      id={id}
      type={type}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      className={`py-[15px] px-[20px] text-sm rounded-full bg-river-blue 
      justify-center text-white inline-flex items-center hover:bg-river-light-blue cursor-pointer 
      transition-all ease-linear ${
        loading ? 'disabled:cursor-wait' : 'disabled:cursor-not-allowed'
      }  disabled:text-gray-300 disabled:bg-river-light-blue
        ${className}`}
    >
      {!(disabled && loading) ? label : 'Please wait...'}

      {disabled && loading && <Icon id="loader" className="w-5 h-5 ml-2" />}
    </button>
  );
}

Button.defaultProps = {
  id: '',
  className: '',
  type: 'button',
  disabled: false,
  loading: false,
};

export default Button;
