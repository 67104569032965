import React from 'react';
import { useNavigate } from 'react-router-dom';
import FormLayout from '../../../components/FormLayout/FormLayout';
import Button from '../../../components/Button/Button';
import { useForm, tempSaveForm } from '../../../context/formContext';
import { paths } from '../../../routes';
import { env } from '../../../utils';

const PAGE = 4;
const STARTER_CARE_PLAN_COST = env('RIVER_STARTER_CARE_PLAN_COST') || 50;
const COMPLETE_CARE_PLAN_COST = env('RIVER_COMPLETE_CARE_PLAN_COST') || 99;

function FormFour() {
  const [{ form }, dispatch] = useForm();
  const navigate = useNavigate();

  const handlePlanSelected = (plan: 'STARTER' | 'COMPLETE' | 'BOTH') => {
    tempSaveForm(dispatch, {
      ...form,
      isOfferingStarterCare: plan === 'STARTER' || plan === 'BOTH',
      isOfferingCompleteCare: plan === 'COMPLETE' || plan === 'BOTH',
    });

    navigate(paths.FORM_FIVE_URL_PATH);
  };

  return (
    <FormLayout page={PAGE} previous={paths.FORM_THREE_URL_PATH}>
      <div className="flex flex-col items-center px-8 pt-0 tablet:pt-7 pb-7">
        <h3 className="font-semibold text-[20px] tablet:text-xl text-river-black mb-8 tablet:mb-12">
          Select your pricing plan
        </h3>

        <div className="grid gap-y-10 tablet:grid-cols-3 tablet:gap-x-7 tablet:gap-y-0 tablet:w-4/5 w-full">
          <div
            className={`flex flex-col bg-white border ${
              form.isOfferingStarterCare && !form.isOfferingCompleteCare
                ? 'border-river-blue'
                : 'border-[#B6B6B6]'
            } text-center px-6 w-full rounded-2xl py-8 space-y-5 text-river-black items-center relative`}
          >
            {form.isOfferingStarterCare && !form.isOfferingCompleteCare && (
              <span
                className={`bg-river-blue text-white rounded-full px-3 
              text-[13px] absolute -top-[15px]`}
              >
                SELECTED
              </span>
            )}

            <h2 className="text-lg font-semibold !mt-0">
              River Health Starter Plan
            </h2>

            <div className="text-sm tablet:text-xs">
              <span className="font-medium">Basic care</span>
              <p className="font-light text-[#7A7A7A]">
                Does not reflect employer discount if any
              </p>
            </div>

            <div className="text-river-deep-orange w-[80%]">
              <h3 className="text-[28px] leading-[35px] tracking-[0.032em] font-semibold">
                ${STARTER_CARE_PLAN_COST}
              </h3>

              <span className="text-[13px] font-medium">PER MONTH</span>

              <hr className="mt-1 border-river-deep-orange border-t-[1.5px]" />
            </div>

            <div>
              <h3 className="font-semibold text-[13px] mb-1">
                WHAT’S INCLUDED
              </h3>
              <ul className="space-y-2 text-sm tablet:text-xs">
                <li>$0 Unlimited Virtual Care</li>
                <li>$0 Doctor’s Office Visits</li>
                <li>$0 Formulary Prescriptions</li>
                <li>$0 Lab Testing</li>
                <li>$0 Wellness Services</li>
                <li>$0 Virtual Talk Therapy</li>
              </ul>
            </div>

            <div className="flex-1 flex flex-col items-center justify-center mobile:!mt-14">
              <Button
                label={
                  form.isOfferingStarterCare && !form.isOfferingCompleteCare
                    ? 'Continue'
                    : 'Choose plan'
                }
                onClick={() => {
                  handlePlanSelected('STARTER');
                }}
                className="w-[225px] disabled:bg-gray-200 disabled:text-[#B6B6B6]"
              />
            </div>
          </div>

          <div
            className={`flex flex-col bg-white border  ${
              form.isOfferingCompleteCare || form.isOfferingStarterCare
                ? form.isOfferingCompleteCare && !form.isOfferingStarterCare
                  ? 'border-river-blue'
                  : 'border-[#B6B6B6]'
                : 'border-river-deep-orange'
            }  text-center
            px-6 w-full rounded-2xl py-8 space-y-5 text-river-black items-center relative justify-center`}
          >
            {((form.isOfferingCompleteCare && !form.isOfferingStarterCare) ||
              (!form.isOfferingCompleteCare &&
                !form.isOfferingStarterCare)) && (
              <span
                className={`${
                  form.isOfferingCompleteCare
                    ? 'bg-river-blue'
                    : 'bg-river-deep-orange'
                }  text-white rounded-full px-3 
              text-[13px] absolute -top-[15px]`}
              >
                {form.isOfferingCompleteCare ? 'SELECTED' : 'MOST PREFERRED'}
              </span>
            )}

            <h2 className="text-lg font-semibold !mt-0">
              River Health Complete Plan
            </h2>

            <div className="text-sm tablet:text-xs">
              <span className="font-medium">Basic care + more</span>
              <p className="font-light text-[#7A7A7A]">
                Does not reflect employer discount if any
              </p>
            </div>

            <div className="text-river-deep-orange w-[80%]">
              <h3 className="text-[28px] leading-[35px] tracking-[0.032em] font-semibold">
                ${COMPLETE_CARE_PLAN_COST}
              </h3>

              <span className="text-[13px] font-medium">PER MONTH</span>

              <hr className="mt-1 border-river-deep-orange border-t-[1.5px]" />
            </div>

            <div>
              <h3 className="font-semibold text-[13px] mb-1">
                WHAT’S INCLUDED
              </h3>
              <ul className="space-y-2 text-sm tablet:text-xs">
                <li>0$ Unlimited Virtual Care</li>
                <li>0$ Specialty Care</li>
                <li>$0 Doctor’s Office Visits</li>
                <li>$0 Formulary Prescriptions</li>
                <li>$0 Lab Testing</li>
                <li>$0 Wellness Services</li>
                <li>$0 Virtual Talk Therapy</li>
              </ul>
            </div>

            <div className="flex-1 flex flex-col items-center justify-center mobile:!mt-14 !mt-10">
              <Button
                label={
                  form.isOfferingCompleteCare && !form.isOfferingStarterCare
                    ? 'Continue'
                    : 'Choose plan'
                }
                onClick={() => handlePlanSelected('COMPLETE')}
                className="w-[225px] disabled:bg-gray-200 disabled:text-[#B6B6B6]"
              />
            </div>
          </div>

          <div
            className={`flex flex-col bg-white border ${
              form.isOfferingStarterCare && form.isOfferingCompleteCare
                ? 'border-river-blue'
                : 'border-[#B6B6B6]'
            } text-center px-6 w-full rounded-2xl pt-8 tablet:pt-20 pb-8 space-y-5 text-river-black items-center relative`}
          >
            {form.isOfferingStarterCare && form.isOfferingCompleteCare && (
              <span
                className={`bg-river-blue text-white rounded-full px-3 
              text-[13px] absolute -top-[15px]`}
              >
                SELECTED
              </span>
            )}

            <h2 className="text-lg font-semibold">Offer Both Plans</h2>

            <div className="text-sm tablet:text-xs">
              <span className="font-medium">Basic care + more</span>
              <p className="font-light text-[#7A7A7A]">
                Allow employees to pick between plans.
              </p>
            </div>

            <div className="text-river-deep-orange w-[80%]">
              <hr className="mt-1 border-river-deep-orange border-t-[1.5px]" />
            </div>

            <div>
              <h3 className="font-semibold text-[13px] mb-1">
                WHAT’S INCLUDED
              </h3>
              <ul className="space-y-2 text-sm tablet:text-xs">
                <li>River Health Starter Plan</li>
                <li>River Health Complete Plan</li>
              </ul>
            </div>

            <div className="flex-1 flex items-end justify-center mobile:!mt-14">
              <Button
                label={
                  form.isOfferingStarterCare && form.isOfferingCompleteCare
                    ? 'Continue'
                    : 'Choose plan'
                }
                onClick={() => handlePlanSelected('BOTH')}
                className="w-[225px] disabled:bg-gray-200 disabled:text-[#B6B6B6]"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col tablet:w-4/5 w-full mt-8 px-2 tablet:px-0">
          <h1 className="text-sm font-bold !mb-1 text-river-blue">Note:</h1>
          <div className="mb-5">
            <h3 className="text-sm font-semibold">
              River Health Starter Plan:
            </h3>
            <p className="text-xs font-light text-[#7A7A7A]">
              <strong className="text-river-deep-orange">*</strong> Medications
              are limited to formulary drugs only. Office visits are limited to
              3 visits per plan year with a primary care provider. Talk therapy
              is available online up to 24 times a year.
            </p>
          </div>

          <div>
            <h3 className="text-sm font-semibold">
              River Health Complete Plan:
            </h3>
            <p className="text-xs font-light text-[#7A7A7A]">
              <strong className="text-river-deep-orange">*</strong> Medications
              are limited to formulary drugs only. Office visits are limited to
              3 visits per plan year with a primary care provider. Talk therapy
              is available online up to 24 times a year.
            </p>
          </div>
        </div>
      </div>
    </FormLayout>
  );
}

export default FormFour;
