import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import { getRegisteredCompanies } from '../../api';
import Icon from '../../components/Icon/Icon';
import { showAlert, useAuth } from '../../context/authContext';
import { initialState, tempSaveForm, useForm } from '../../context/formContext';
import { paths } from '../../routes';
import { Company } from '../../types';
import { HTTPError } from '../../utils/http';

function Home() {
  const [{ user }, dispatch] = useAuth();
  const [{ form }, formDispatch] = useForm();
  const navigate = useNavigate();

  React.useEffect(() => {
    formDispatch({ type: 'SET_PAGE_NUMBER', payload: 0 });
  }, [formDispatch]);

  const { isLoading, data: getCompanies } = useQuery(
    ['companies'],
    getRegisteredCompanies,
    {
      retry: 1,
      onError: (error: HTTPError) => {
        showAlert(dispatch, {
          show: true,
          type: 'ERROR',
          message: error.message,
        });
      },
    },
  );

  const companies = (getCompanies || []).sort((a: Company, b: Company) =>
    b.createdAt.localeCompare(a.createdAt),
  );

  const getHumanDays = (offerAt: string) => {
    const diffDays = moment(offerAt).diff(moment(), 'days');
    if (diffDays > 7) {
      return moment(offerAt).format('DD-MMM-YYYY');
    }
    if (diffDays > 0 && diffDays <= 7) {
      return moment(offerAt).fromNow();
    }

    return 'Ongoing';
  };

  return (
    <div className="flex flex-col px-8 items-start tablet:items-center pb-8">
      <div className="text-left tablet:text-center">
        <h1 className="font-poppins font-semibold text-[30px] text-river-black">
          Hi{' '}
          {user?.firstName || companies?.[0]?.authorizedOfficerName || 'there'}
          ...
        </h1>
        <p className="text-river-black text-lg mt-2">
          List of all registered companies
        </p>
      </div>

      <div />

      <div className="w-full tablet:w-[40%] pt-6">
        {isLoading ? (
          <div
            className="absolute  w-full h-full top-0 left-0 flex items-center 
                justify-center rounded-xl"
          >
            <BounceLoader color="black" size={60} />
          </div>
        ) : companies.length === 0 && !form.name ? (
          <div className="text-center">
            No company found!{' '}
            <button
              type="button"
              className="text-river-blue underline underline-offset-2"
              onClick={() => navigate(paths.FORM_ONE_URL_PATH)}
            >
              Add new
            </button>
          </div>
        ) : (
          <>
            {form.name && (
              <div>
                <h3 className="font-medium text-sm mb-2">
                  Current Submission:
                </h3>
                <div
                  className="w-full flex flex-col tablet:flex-row tablet:items-center justify-between min-h-[80px]
          text-river-black bg-white px-5 py-5 tablet:py-3 rounded-md border shadow-sm
          text-left space-y-4 tablet:space-y-0"
                >
                  <div>{form.name}</div>
                  <div className="flex items-center space-x-4 justify-between tablet:justify-start">
                    <button
                      onClick={() => navigate(paths.FORM_ONE_URL_PATH)}
                      type="button"
                      className="border text-[13px] rounded-md px-2 py-2 border-river-blue
                leading-none text-river-blue font-medium"
                    >
                      Continue
                    </button>
                    <button
                      onClick={() => {
                        tempSaveForm(formDispatch, initialState.form);
                      }}
                      type="button"
                      className="rounded-full w-[20px] h-[20px] border-river-black border right-5 top-5
                flex items-center justify-center"
                    >
                      <Icon
                        className="w-2 h-2 fill-river-black text-river-black"
                        id="close"
                      />
                    </button>
                  </div>
                </div>
                <hr className="my-7 w-[50%] mx-auto" />
              </div>
            )}

            {companies.length > 0 && (
              <>
                {!form.name && (
                  <div className="text-left mb-4">
                    Register new company?{' '}
                    <button
                      type="button"
                      className="text-river-blue underline underline-offset-2"
                      onClick={() => navigate(paths.FORM_ONE_URL_PATH)}
                    >
                      Get started
                    </button>
                  </div>
                )}

                <div className="space-y-5">
                  {companies.map((fndCompany: Company) => (
                    <a
                      href={paths.ENROLLED_EMPLOYEES_URL_PATH.replace(
                        ':companyId',
                        fndCompany.id,
                      )}
                      key={fndCompany.name}
                      className="w-full flex flex-col tablet:flex-row tablet:items-center justify-between min-h-[80px] text-river-black 
                      bg-white px-5 py-5 tablet:py-3 rounded-md border shadow-sm text-left space-y-4 tablet:space-y-0 hover:opacity-80"
                    >
                      <div className="flex flex-col">
                        <h3 className="font-medium">{fndCompany.name}</h3>
                        <div>
                          <span className="text-xs text-river-deep-gray">
                            Offer Starts:{' '}
                            {getHumanDays(fndCompany.offersStartsAt)}
                          </span>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Home;
