import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Transition, Dialog } from '@headlessui/react';
import FormLayout from '../../components/FormLayout/FormLayout';
import Graphic from '../../components/Graphic/Graphic';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import { paths } from '../../routes';

function SummaryManual() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <>
      <FormLayout page={0}>
        <div
          className="flex flex-col items-center text-center w-full tablet:w-[55%] 
          mx-auto px-8 pt-0 tablet:pt-7 pb-7"
        >
          <Graphic
            id="graphicUploadEligibilityByMail"
            className="w-[400px] tablet:w-[450px]"
          />
          <div className="!-mt-16">
            <h3 className="font-semibold text-[20px] tablet:text-xl text-river-black mb-4 tablet:mb-4">
              Manual Integration
            </h3>
            <p className="">
              Use the template below to send a list of eligible employees to{' '}
              <a
                href="mailto:team@helloriver.com"
                className="text-river-deep-orange"
              >
                team@helloriver.com
              </a>{' '}
              at least one (1) week before your start date of{' '}
              <span className="whitespace-pre">2022-10-29.</span>
            </p>
          </div>
          <div className="mt-16">
            <a
              onClick={() => setOpen(true)}
              href={`${window.location.origin}/assets/docx/docx_river_health_eligibility.xlsx`}
              download
              className="w-fit rounded-full bg-river-blue text-white hover:bg-river-blue
              active:bg-river-blue py-4 px-8 transition-all ease-linear"
            >
              Download template
            </a>
          </div>
        </div>
      </FormLayout>

      <Transition.Root show={open} as={React.Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0" onClose={setOpen}>
          <div className="flex flex-col items-center justify-end tablet:justify-center min-h-screen pt-4 px-4 pb-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="tablet:w-[30%] w-full inline-block relative bg-white rounded-xl text-left shadow-xl 
                transform transition-all"
              >
                <button
                  onClick={() => setOpen(false)}
                  type="button"
                  className="rounded-full w-[30px] h-[30px] border-river-black border absolute right-5 top-5 
                  flex items-center justify-center"
                >
                  <Icon
                    className="w-3 h-3 fill-river-black text-river-black"
                    id="close"
                  />
                </button>
                <div className="flex flex-col items-center text-center w-full px-6 pb-10">
                  <Graphic
                    id="graphicModalNoticeAllDone"
                    className="w-[180px]"
                  />
                  <div className="!-mt-16">
                    <h3 className="font-semibold text-[20px] text-river-black mb-4 tablet:mb-4">
                      All done!
                    </h3>
                    <p className="">
                      Your employer application is complete! We look forward to
                      receiving your eligibility file. Got questions? Send us a
                      note at{' '}
                      <span className="text-river-deep-orange">
                        team@helloriver.com.
                      </span>
                    </p>
                  </div>
                  <div className="mt-16">
                    <Button
                      label="All set!"
                      onClick={() => navigate(paths.HOME_URL_PATH)}
                      className="w-[230px] text-sm"
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default SummaryManual;
