import * as React from 'react';

type CheckBoxProps = {
  className?: string;
  name: string;
  id?: string;
  disabled?: boolean;
  error?: boolean;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function CheckBox({
  className,
  name,
  id,
  disabled,
  error,
  checked,
  value,
  onChange,
}: CheckBoxProps) {
  return (
    <input
      style={{ boxShadow: 'none' }}
      className={`${className} ${
        error ? 'text-river-red !border-river-red' : ''
      } `}
      type="checkbox"
      name={name}
      id={id}
      disabled={disabled}
      value={value}
      onChange={onChange}
      checked={checked}
    />
  );
}

CheckBox.defaultProps = {
  className: '',
  id: '',
  disabled: false,
  error: false,
};

export default CheckBox;
