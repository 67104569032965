/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-param-reassign */
import axios, {
  AxiosRequestTransformer,
  AxiosResponseTransformer,
  AxiosRequestConfig,
} from 'axios';
import toSnakeCase from 'snakecase-keys';
import toCamelCase from 'camelcase-keys';
import { env, getCookie } from '.';

export type HTTPError = {
  message: string;
  status?: number;
  config?: Record<string, unknown>;
};

const BASE_URL = env('RIVER_API_URL');

const objectToQueryString = (obj: Record<string, unknown>) => {
  const data = obj && toSnakeCase(obj, { deep: true });
  const params = new URLSearchParams(data);
  return params.toString();
};

const transformRequest = [
  (data: Record<string, unknown>) => {
    return data && toSnakeCase(data, { deep: true });
  },
  ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
];

const transformResponse = [
  ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
  (data: Record<string, unknown>) => {
    return data && toCamelCase(data, { deep: true });
  },
];

export const formatError = (error: any): HTTPError => {
  if (error?.response) {
    const response = error?.response;

    const config = {
      url: response?.config?.url || '',
      method: response?.config?.method || '',
      data: response?.config?.data || '',
    };

    const status = response?.status;

    let message = response?.data?.error || '';

    switch (status) {
      case 400:
        message = `Bad request ${message ? `(${message})` : ''}`;
        break;
      case 401:
        message = `Unauthorized ${message ? `(${message})` : ''}`;
        break;
      case 403:
        message = `Forbidden ${message ? `(${message})` : ''}`;
        break;
      case 404:
        message = `Not found ${message ? `(${message})` : ''}`;
        break;
      case 422:
        message = `Unprocessable entity`;
        break;
      case 500:
        message = `Internal server error ${message ? `(${message})` : ''}`;
        break;
      default:
        message = `Sorry! Something unusual happened at our end${
          message ? ` (${message})` : ''
        }. Please contact our support team!`;
    }

    return {
      message,
      status,
      config,
    };
  }

  return {
    message: error?.message || 'Unknown error',
  };
};

const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: objectToQueryString,
  },
  transformRequest,
  transformResponse,
});

http.interceptors.request.use(
  (config: any) => {
    const token = getCookie('_river_employer_tokid');

    if (token && config.baseURL === BASE_URL) {
      config = {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  response => {
    return response?.data;
  },
  error => {
    return Promise.reject(formatError(error));
  },
);

export default http;
