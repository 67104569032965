import Cookies from 'js-cookie';
import * as CryptoJS from 'crypto-js';
import { marked } from 'marked';

const envVars = process.env;

export const env = (value: string) => {
  const key = `REACT_APP_${value}`;

  if (!(key in envVars)) {
    throw new Error(`no env config found for ${value}`);
  }

  return envVars[key];
};

const passPhrase = env('RIVER_PASSPHRASE') || '';

export const getCookie = (key: string) => {
  const item = Cookies.get(key);

  if (!item) {
    return null;
  }

  const decipheredValue = CryptoJS.AES.decrypt(item, passPhrase);

  return decipheredValue.toString(CryptoJS.enc.Utf8);
};

export const setCookie = (key: string, value: string, expires = 7) => {
  const cipheredValue = CryptoJS.AES.encrypt(value, passPhrase).toString();

  if (env('RIVER_ENV') === 'development') {
    Cookies.set(key, cipheredValue);
    return;
  }

  if (env('RIVER_ENV') === 'staging') {
    Cookies.set(key, cipheredValue);
    return;
  }

  const options: Cookies.CookieAttributes = {
    expires,
    sameSite: 'Lax',
    secure: true,
  };

  Cookies.set(key, cipheredValue, options);
};

export const storage = {
  set: (key: string, value: string) => {
    const cipheredValue = CryptoJS.AES.encrypt(value, passPhrase).toString();
    localStorage.setItem(key, cipheredValue);
  },
  get: (key: string) => {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    const decipheredValue = CryptoJS.AES.decrypt(item, passPhrase);

    return decipheredValue.toString(CryptoJS.enc.Utf8);
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};

export const moveFilteredItemsToTop = <T>({
  array,
  findFunction,
}: {
  array: Array<T>;
  findFunction: (item: T) => boolean;
}) => [
  ...array.filter(findFunction),
  ...array.filter(item => !findFunction(item)),
];

export function markdown(
  src: string,
  options?: marked.MarkedOptions,
  newTab = false,
) {
  const renderer = new marked.Renderer();
  renderer.link = function (href: string, title: string, text: string) {
    const link = marked.Renderer.prototype.link.apply(this, [
      href,
      title,
      text,
    ]);
    if (newTab) {
      return link.replace('<a', "<a target='_self'");
    }
    return link.replace('<a', "<a target='_blank'");
  };

  marked.setOptions({
    renderer,
  });

  return marked(src, options);
}

export const US_STATES = [
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'DC',
    label: 'Washington DC',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
];
