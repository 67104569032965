import { AuthToken, Company, Form } from '../types';
import http from '../utils/http';

export async function getAuthUser() {
  const response = await http.get('/user/profile');
  return response.data;
}

export async function getRegisteredCompanies(): Promise<Company[]> {
  const response = await http.get('/employer/companies');
  return response.data;
}

export async function getCompany(companyId?: string) {
  const companiesRes = await http.get('/employer/companies');

  let company = companiesRes.data.find(
    (fndCompany: Company) => fndCompany.id === companyId,
  );

  const employeesRes = await http.get(
    `/employer/companies/${companyId}/employees`,
  );

  company = {
    ...company,
    employees: employeesRes.data || [],
  };

  return company;
}

export async function deleteCompanyEmployee(companyId: string, userId: string) {
  await http.delete(`/employer/companies/${companyId}/employees/${userId}`);
}

export async function sendOTP(phoneNumber: string) {
  return http.post('/auth/phone/send-code', {
    phoneNumber,
  });
}

export async function verifyOTP({
  phoneNumber,
  otp,
}: {
  phoneNumber: string;
  otp: string;
}) {
  const response = await http.post<AuthToken>('/auth/phone/verify-code', {
    phoneNumber,
    verificationCode: otp,
  });
  return response.data;
}

export async function createCompany(fndForm: Form) {
  const response = await http.post('/employer/company', fndForm);
  return response.data;
}

export async function getMergeLinkToken(
  email: string,
  organizationName: string,
) {
  const response = await http.post('/integrations/merge/link-token', {
    email,
    organizationName,
  });
  return response.data;
}

export async function sendTokenToMerge(publicToken: string) {
  const response = await http.post('/integrations/merge/public-token', {
    publicToken,
  });
  return response.data;
}
