/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormLayout, {
  submitForm,
} from '../../../components/FormLayout/FormLayout';
import { useForm, tempSaveForm } from '../../../context/formContext';
import { paths } from '../../../routes';
import { Form } from '../../../types';
import Input from '../../../components/Inputs/Input/Input';
import CheckBox from '../../../components/Inputs/CheckBox/CheckBox';

const PAGE = 6;

function FormSix() {
  const [{ form }, dispatch] = useForm();
  const navigate = useNavigate();

  const validationSchema: Yup.ObjectSchema<Partial<Record<keyof Form, any>>> =
    Yup.object().shape({
      isOfferingFamilyPlan: Yup.boolean().required(),
      amountCoveredForFamilyPlan: Yup.number()
        .transform(value => (Number.isNaN(value) ? undefined : value))
        .when('isOfferingFamilyPlan', {
          is: true,
          then: Yup.number().required(),
        }),
      ...(form.isOfferingStarterCare &&
        form.isOfferingCompleteCare && {
          switchToHigherPlan: Yup.string().required(),
          planPaymentOption: Yup.string().when('switchToHigherPlan', {
            is: true,
            then: Yup.string().required(),
          }),
        }),
    });

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      initialValues={{
        isOfferingFamilyPlan: form.isOfferingFamilyPlan,
        amountCoveredForFamilyPlan: form.amountCoveredForFamilyPlan,
        switchToHigherPlan: form.switchToHigherPlan,
        planPaymentOption: form.planPaymentOption,
      }}
      validationSchema={validationSchema}
      onSubmit={data => {
        tempSaveForm(dispatch, {
          ...form,
          ...{
            ...data,
            isOfferingFamilyPlan: data.isOfferingFamilyPlan,
          },
        });
        navigate(paths.FORM_SEVEN_URL_PATH);
      }}
    >
      {formik => {
        const { values, handleChange, errors, setFieldValue } = formik;

        return (
          <FormLayout
            page={PAGE}
            previous={paths.FORM_FIVE_URL_PATH}
            continueHandler={async () => {
              await submitForm(formik);
            }}
          >
            <div className="space-y-6 max-w-[720px] mx-auto px-8 pt-0 tablet:pt-7 pb-7">
              {form.isOfferingStarterCare && form.isOfferingCompleteCare ? (
                <>
                  <div className="space-y-3">
                    <label className="form-label" htmlFor="switchToHigherPlan">
                      Should employees be allowed to switch to higher-tier
                      plans?
                    </label>
                    <div className="grid tablet:grid-cols-2 grid-cols-1 gap-4">
                      <label
                        htmlFor="yesToSwitch"
                        className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                          errors.switchToHigherPlan &&
                          !values.switchToHigherPlan
                            ? '!border-river-red'
                            : ''
                        }`}
                      >
                        <CheckBox
                          id="yesToSwitch"
                          name="switchToHigherPlan"
                          value="Yes"
                          checked={
                            values.switchToHigherPlan === undefined
                              ? false
                              : !!values.switchToHigherPlan
                          }
                          onChange={event => {
                            setFieldValue(
                              'switchToHigherPlan',
                              !!event.target.checked,
                            );
                          }}
                          className="align-middle cursor-pointer"
                        />{' '}
                        <span className="align-middle text-[16px] ml-2 font-normal">
                          Yes
                        </span>
                      </label>
                      <label
                        htmlFor="noToSwitch"
                        className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                          errors.switchToHigherPlan &&
                          !values.switchToHigherPlan
                            ? '!border-river-red'
                            : ''
                        }`}
                      >
                        <CheckBox
                          id="noToSwitch"
                          name="switchToHigherPlan"
                          value="No"
                          checked={
                            values.switchToHigherPlan === undefined
                              ? false
                              : !values.switchToHigherPlan
                          }
                          onChange={event => {
                            setFieldValue(
                              'switchToHigherPlan',
                              !event.target.checked,
                            );
                          }}
                          className="align-middle cursor-pointer"
                        />{' '}
                        <span className="align-middle text-[16px] ml-2 font-normal">
                          No
                        </span>
                      </label>
                    </div>
                  </div>

                  {values.switchToHigherPlan ? (
                    <div className="space-y-3">
                      <label className="form-label" htmlFor="planPaymentOption">
                        How should employees pay for new plan upgrades?
                      </label>

                      <div className="grid tablet:grid-cols-3 grid-cols-1 gap-4">
                        <label
                          htmlFor="sponsored"
                          className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                            errors.planPaymentOption &&
                            !values.planPaymentOption
                              ? '!border-river-red'
                              : ''
                          }`}
                        >
                          <CheckBox
                            id="sponsored"
                            name="planPaymentOption"
                            value="Fully Sponsored"
                            className="align-middle cursor-pointer"
                            checked={
                              values.planPaymentOption === 'Fully Sponsored'
                            }
                            onChange={event => {
                              setFieldValue(
                                'planPaymentOption',
                                event.target.checked ? 'Fully Sponsored' : '',
                              );
                            }}
                          />{' '}
                          <span className="align-middle text-[16px] ml-2 font-normal">
                            Fully Sponsored
                          </span>
                        </label>

                        <label
                          htmlFor="payroll"
                          className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                            errors.planPaymentOption &&
                            !values.planPaymentOption
                              ? '!border-river-red'
                              : ''
                          }`}
                        >
                          <CheckBox
                            id="payroll"
                            name="planPaymentOption"
                            value="Payroll Deduction"
                            className="align-middle cursor-pointer"
                            checked={
                              values.planPaymentOption === 'Payroll Deduction'
                            }
                            onChange={event => {
                              setFieldValue(
                                'planPaymentOption',
                                event.target.checked ? 'Payroll Deduction' : '',
                              );
                            }}
                          />{' '}
                          <span className="align-middle text-[16px] ml-2 font-normal">
                            Payroll Deduction
                          </span>
                        </label>

                        <label
                          htmlFor="credit"
                          className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                            errors.planPaymentOption &&
                            !values.planPaymentOption
                              ? '!border-river-red'
                              : ''
                          }`}
                        >
                          <CheckBox
                            id="credit"
                            name="planPaymentOption"
                            value="Credit Card"
                            className="align-middle cursor-pointer"
                            checked={values.planPaymentOption === 'Credit Card'}
                            onChange={event => {
                              setFieldValue(
                                'planPaymentOption',
                                event.target.checked ? 'Credit Card' : '',
                              );
                            }}
                          />{' '}
                          <span className="align-middle text-[16px] ml-2 font-normal">
                            Credit Card
                          </span>
                        </label>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}

              <div className="space-y-3">
                <label className="form-label" htmlFor="isOfferingFamilyPlan">
                  {` Do you want to offer this plan to your employees' families?`}
                </label>

                <div className="grid tablet:grid-cols-2 grid-cols-1 gap-4">
                  <label
                    htmlFor="yesToPlan"
                    className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                      errors.isOfferingFamilyPlan &&
                      !values.isOfferingFamilyPlan
                        ? '!border-river-red'
                        : ''
                    }`}
                  >
                    <CheckBox
                      id="yesToPlan"
                      name="isOfferingFamilyPlan"
                      value="Yes"
                      checked={
                        values.isOfferingFamilyPlan === undefined
                          ? false
                          : !!values.isOfferingFamilyPlan
                      }
                      onChange={event => {
                        setFieldValue(
                          'isOfferingFamilyPlan',
                          !!event.target.checked,
                        );
                      }}
                      className="align-middle cursor-pointer"
                    />{' '}
                    <span className="align-middle text-[16px] ml-2 font-normal">
                      Yes
                    </span>
                  </label>

                  <label
                    htmlFor="noToPlan"
                    className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                      errors.isOfferingFamilyPlan &&
                      !values.isOfferingFamilyPlan
                        ? '!border-river-red'
                        : ''
                    }`}
                  >
                    <CheckBox
                      id="noToPlan"
                      name="isOfferingFamilyPlan"
                      value="No"
                      checked={
                        values.isOfferingFamilyPlan === undefined
                          ? false
                          : !values.isOfferingFamilyPlan
                      }
                      onChange={event => {
                        setFieldValue(
                          'isOfferingFamilyPlan',
                          !event.target.checked,
                        );
                      }}
                      className="align-middle cursor-pointer"
                    />{' '}
                    <span className="align-middle text-[16px] ml-2 font-normal">
                      No
                    </span>
                  </label>
                </div>
              </div>

              {values.isOfferingFamilyPlan ? (
                <div className="space-y-3">
                  <label
                    className="form-label"
                    htmlFor="amountCoveredForFamilyPlan"
                  >
                    How much will the company contribute towards family plans?
                  </label>
                  <Input
                    name="amountCoveredForFamilyPlan"
                    id="amountCoveredForFamilyPlan"
                    type="number"
                    value={
                      Number.isNaN(values.amountCoveredForFamilyPlan)
                        ? ''
                        : `${values.amountCoveredForFamilyPlan}`
                    }
                    onChange={handleChange}
                    placeholder="35"
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={Boolean(errors.amountCoveredForFamilyPlan)}
                  />
                </div>
              ) : null}
            </div>
          </FormLayout>
        );
      }}
    </Formik>
  );
}

export default FormSix;
