import React from 'react';
import { useNavigate } from 'react-router-dom';
import WelcomeIntro from '../../assets/images/image_intro_welcome.svg';
import WelcomeIntroMobile from '../../assets/images/image_intro_welcome_mobile.svg';
import Button from '../../components/Button/Button';
import { ReactComponent as RiverLogo } from '../../assets/icons/icon_river_logo.svg';
import Icon from '../../components/Icon/Icon';
import { env } from '../../utils';
import { paths } from '../../routes';

function Intro() {
  const navigate = useNavigate();

  return (
    <div className="w-full min-h-screen bg-white">
      <div className="grid grid-cols-1 tablet:grid-cols-2 h-screen tablet:h-full overflow-auto">
        <div
          className="text-center tablet:text-left tablet:flex flex-col px-8 tablet:px-24 tablet:py-10 
          mt-14 tablet:mt-0 justify-between mobile:order-last mobile:h-[70vh]"
        >
          <div
            className="mobile:fixed mobile:w-full mobile:bg-white mobile:shadow-xl mobile:top-0 
            mobile:left-0 mobile:px-6 mobile:pt-6 mobile:pb-7"
          >
            <a
              href={env('RIVER_EMPLOYER_APP_URL')}
              className="w-20 tablet:w-16 block"
            >
              <RiverLogo className="w-[70px] tablet:w-16" />
            </a>
          </div>

          <div className="tablet:flex-1 flex flex-col justify-center items-center">
            <div className="-mt-5">
              <h1 className="font-semibold text-[30px] tablet:text-[34px] leading-[48px] tracking-[0.032em]">
                Hello there
                <Icon id="waveEmoji" className="inline-flex ml-3" />
              </h1>
              <p className="tracking-wider text-[18px] mb-12 w-full tablet:w-[80%]">
                We are excited to provide quality healthcare services to your
                team. Let’s create your employer account.
              </p>
              <Button
                className="w-[225px]"
                label="Get Started"
                onClick={() => {
                  window.sessionStorage.setItem(
                    '_river_employer_started',
                    'true',
                  );
                  navigate(paths.AUTH_URL_PATH);
                }}
              />
              <span className="flex items-center justify-center tablet:justify-start  mt-4 text-[15px] ml-1 invisible">
                <Icon
                  id="clock"
                  className="inline-flex mr-[5px] w-[18px] h-[18px]"
                />
                Takes 3 min
              </span>
            </div>
          </div>

          <div
            className="bg-[#EEFBFF] text-xs inline-flex items-center w-fit rounded-full px-3 
            py-2 space-x-3 tablet:space-x-2 text-left mt-[70px] tablet:mt-0"
          >
            <span
              className="shrink-0 bg-river-blue rounded-full inline-flex items-center 
              justify-center w-[23px] h-[23px]"
            >
              <Icon id="exclamation" className="text-white" />
            </span>
            <span>
              Already have an account?&nbsp;&nbsp;
              <a
                href={paths.AUTH_URL_PATH}
                className="text-river-deep-orange underline underline-offset-2"
              >
                Login
              </a>
              &nbsp;&nbsp;
            </span>
          </div>
        </div>

        <div className="w-full h-[30vh] mt-[76px] tablet:mt-0 tablet:h-screen">
          <img
            className="w-full h-full object-cover object-center hidden tablet:block"
            src={WelcomeIntro}
            alt="welcome"
          />

          <img
            className="w-full h-full object-cover object-center block tablet:hidden"
            src={WelcomeIntroMobile}
            alt="welcome"
          />
        </div>
      </div>
    </div>
  );
}

export default Intro;
