import Intro from './pages/Intro/Intro';
import Auth from './pages/Auth/Auth';
import Home from './pages/Home/Home';
import FormOne from './pages/Forms/FormOne/FormOne';
import FormTwo from './pages/Forms/FormTwo/FormTwo';
import FormThree from './pages/Forms/FormThree/FormThree';
import FormFour from './pages/Forms/FormFour/FormFour';
import FormFive from './pages/Forms/FormFive/FormFive';
import FormSix from './pages/Forms/FormSix/FormSix';
import SummaryManual from './pages/SummaryManual/SummaryManual';
import EnrolledEmployees from './pages/EnrolledEmployees/EnrolledEmployees';
import FormSeven from './pages/Forms/FormSeven/FormSeven';

export const paths = {
  INTRO_URL_PATH: '/get-started',
  AUTH_URL_PATH: '/login',
  HOME_URL_PATH: '/',
  FORM_ONE_URL_PATH: '/form-1',
  FORM_TWO_URL_PATH: '/form-2',
  FORM_THREE_URL_PATH: '/form-3',
  FORM_FOUR_URL_PATH: '/form-4',
  FORM_FIVE_URL_PATH: '/form-5',
  FORM_SIX_URL_PATH: '/form-6',
  FORM_SEVEN_URL_PATH: '/form-7',
  SUMMARY_MANUAL_URL_PATH: '/summary-manual',
  ENROLLED_EMPLOYEES_URL_PATH: '/:companyId/enrolled-employees',
};

const routes = [
  {
    path: paths.INTRO_URL_PATH,
    component: Intro,
    isPrivate: false,
  },
  {
    path: paths.AUTH_URL_PATH,
    component: Auth,
    isPrivate: false,
  },
  {
    path: paths.HOME_URL_PATH,
    component: Home,
    isPrivate: true,
  },
  {
    path: paths.FORM_ONE_URL_PATH,
    component: FormOne,
    isPrivate: true,
  },
  {
    path: paths.FORM_TWO_URL_PATH,
    component: FormTwo,
    isPrivate: true,
  },
  {
    path: paths.FORM_THREE_URL_PATH,
    component: FormThree,
    isPrivate: true,
  },
  {
    path: paths.FORM_FOUR_URL_PATH,
    component: FormFour,
    isPrivate: true,
  },
  {
    path: paths.FORM_FIVE_URL_PATH,
    component: FormFive,
    isPrivate: true,
  },
  {
    path: paths.FORM_SIX_URL_PATH,
    component: FormSix,
    isPrivate: true,
  },
  {
    path: paths.FORM_SEVEN_URL_PATH,
    component: FormSeven,
    isPrivate: true,
  },
  {
    path: paths.SUMMARY_MANUAL_URL_PATH,
    component: SummaryManual,
    isPrivate: true,
  },
  {
    path: paths.ENROLLED_EMPLOYEES_URL_PATH,
    component: EnrolledEmployees,
    isPrivate: true,
  },
];

export default routes;
