import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';
import Icon from '../Icon/Icon';
import Graphic from '../Graphic/Graphic';
import Button from '../Button/Button';
import { paths } from '../../routes';
import { useForm } from '../../context/formContext';

type FormLayoutProps = {
  children: React.ReactNode | React.ReactNode[];
  page: number;
  previous?: string;
  continueHandler?: () => void;
};

function FormLayout({
  children,
  page,
  continueHandler,
  previous,
}: FormLayoutProps) {
  const [, dispatch] = useForm();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch({ type: 'SET_PAGE_NUMBER', payload: page });
  }, [dispatch, page]);

  return (
    <div className="relative z-10">
      <div className="px-16 flex justify-between items-center mobile:hidden">
        {previous && (
          <button
            type="button"
            onClick={() => {
              navigate(previous);
            }}
            className="border borderWidth border-river-blue back-bg h-[45px] w-[45px] p-3 rounded-full 
              flex items-center justify-center"
          >
            <Icon
              className="w-4 h-4 fill-river-blue text-river-blue"
              id="arrowBack"
            />
          </button>
        )}

        <button
          type="button"
          onClick={() => {
            dispatch({ type: 'SET_PAGE_NUMBER', payload: 0 });
            navigate(paths.HOME_URL_PATH);
          }}
          className="p-3 rounded-full elementShadow h-[47px] w-[47px] flex items-center justify-center ml-auto"
        >
          <Icon
            className="w-4 h-4 fill-river-black text-river-black"
            id="close"
          />
        </button>
      </div>

      {children}
      <div className="flex justify-center !pt-14 pb-14">
        {continueHandler && (
          <Button
            label="Continue"
            onClick={continueHandler}
            className="w-[230px]"
          />
        )}
      </div>

      {page > 0 && (
        <div
          className="absolute top-0 left-0 w-full h-full flex flex-row 
        items-center justify-between mobile:hidden -z-10 opacity-70"
        >
          <Graphic id="graphicSquigglesLeft" className="w-[25%] mt-[16%]" />
          <Graphic id="graphicSquigglesRight" className="w-[26%] mt-[9%]" />
        </div>
      )}
    </div>
  );
}

FormLayout.defaultProps = {
  previous: undefined,
  continueHandler: undefined,
};

export async function submitForm(formik: FormikProps<any>) {
  const formValidation = await formik.validateForm();

  const formErrors = Object.entries(formValidation);

  if (formErrors.length > 0) {
    const errorKey = formErrors[0][0];
    const errorMessage = formErrors[0][1];

    const firstError: HTMLInputElement | null = window.document.querySelector(
      `input[name="${errorKey}"]`,
    );

    if (firstError) {
      // if (errorKey === 'offersStartsAt' && errorMessage) {
      //   const errorId = `error-${errorKey}`;
      //   const findSpan = document.getElementById(errorId);

      //   const span = document.createElement('span');
      //   span.id = errorId;
      //   span.innerHTML = `<span class="error-label">${errorMessage}</span>`;

      //   if (findSpan) {
      //     firstError?.parentElement?.replaceChild(span, findSpan);
      //   } else {
      //     firstError?.parentElement?.appendChild(span);
      //   }
      // }

      window.scrollTo({
        behavior: 'smooth',
        top: firstError.offsetTop - 70,
        left: 0,
      });

      firstError.focus();
    }
  }

  formik.handleSubmit();
}

export default FormLayout;
