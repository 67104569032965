import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input/input';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { env, setCookie } from '../../utils';
import { ReactComponent as RiverLogo } from '../../assets/icons/icon_river_logo.svg';
import WelcomeIntro from '../../assets/images/image_intro_welcome.svg';
import WelcomeIntroMobile from '../../assets/images/image_intro_welcome_mobile.svg';
import Button from '../../components/Button/Button';
import {
  showAlert,
  subscribeToExternals,
  useAuth,
} from '../../context/authContext';
import Input from '../../components/Inputs/Input/Input';
import { paths } from '../../routes';
import Alert from '../../components/Alert/Alert';
import { HTTPError } from '../../utils/http';
import {
  getAuthUser,
  getRegisteredCompanies,
  sendOTP,
  verifyOTP,
} from '../../api';

function Auth() {
  const [isLoadingCompanies, setIsLoadingCompanies] = React.useState(false);
  const [{ alert }, dispatch] = useAuth();
  const [hasSentOTP, setHasSentOTP] = React.useState(false);
  const [otpError, setOtpError] = React.useState('');
  const navigate = useNavigate();

  const phoneValidationSchema = Yup.object({
    phoneNumber: Yup.string()
      .test('phone', 'The phone number must be a valid US number.', value =>
        value ? isValidPhoneNumber(value) : false,
      )
      .required('Phone number is required'),
  });

  const { mutate: sendOTPFn, isLoading: isSendingOTP } = useMutation(
    (phoneNumber: string) => sendOTP(phoneNumber),
    {
      onSuccess: () => {
        setHasSentOTP(true);
      },
      onError: (error: HTTPError) => {
        showAlert(dispatch, {
          show: true,
          message: error.message,
          type: 'ERROR',
        });
      },
    },
  );

  const { mutate: verifyOTPFn, isLoading: isVerifyingOTP } = useMutation(
    (verifyData: { phoneNumber: string; otp: string }) => verifyOTP(verifyData),
    {
      onSuccess: async data => {
        setIsLoadingCompanies(true);

        const expires = Math.ceil(
          moment.duration(moment(data.expiresAt).diff(moment())).asDays(),
        );

        await setCookie('_river_employer_tokid', data.token, expires);

        subscribeToExternals(await getAuthUser());

        const companies = await getRegisteredCompanies();

        setIsLoadingCompanies(false);

        if ((companies || []).length === 0) {
          navigate(paths.FORM_ONE_URL_PATH);
        } else {
          navigate(paths.HOME_URL_PATH);
        }
      },
      onError: (error: HTTPError) => {
        showAlert(dispatch, {
          show: true,
          message: error.message,
          type: 'ERROR',
        });
      },
    },
  );

  const handlePinChange = (
    element: EventTarget & HTMLInputElement,
    inputName: string,
    formik: FormikProps<any>,
  ) => {
    if (Number.isNaN(+element.value) || element.value.trim().length <= 0) {
      formik.setFieldValue(inputName, '');
    } else {
      formik.setFieldValue(inputName, element.value);

      if (element.nextSibling) {
        (element.nextSibling as HTMLElement).focus();
      }
    }
  };

  return (
    <Formik
      initialValues={{
        phoneNumber: '',
        pin1: '',
        pin2: '',
        pin3: '',
        pin4: '',
      }}
      validationSchema={!hasSentOTP ? phoneValidationSchema : undefined}
      validateOnBlur={false}
      onSubmit={values => {
        if (values.phoneNumber && !hasSentOTP) {
          sendOTPFn(values.phoneNumber);
          return;
        }

        if (
          hasSentOTP &&
          (!values.pin1 || !values.pin2 || !values.pin3 || !values.pin4)
        ) {
          setOtpError('Verification code is required.');
          return;
        }

        const otp = `${values.pin1}${values.pin2}${values.pin3}${values.pin4}`;

        verifyOTPFn({ phoneNumber: values.phoneNumber, otp });
      }}
    >
      {formik => {
        const { values, handleSubmit, errors } = formik;

        return (
          <div className="w-full min-h-screen bg-white">
            <div className="grid grid-cols-1 tablet:grid-cols-2 h-screen tablet:h-full overflow-auto">
              <Alert
                show={alert.show}
                type={alert.type}
                message={alert.message}
                onAction={() => showAlert(dispatch)}
                onActionMessage="Close"
              />

              <div
                className="text-center tablet:text-left tablet:flex flex-col px-8 tablet:px-24 tablet:py-10 
              mt-14 tablet:mt-0 justify-between mobile:order-last mobile:h-[70vh] tablet:relative"
              >
                <div
                  className="mobile:fixed mobile:w-full mobile:bg-white mobile:shadow-xl mobile:top-0 
                mobile:left-0 mobile:px-6 mobile:pt-5 mobile:pb-6"
                >
                  <a
                    href={env('RIVER_EMPLOYER_APP_URL')}
                    className="w-20 tablet:w-16 block"
                  >
                    <RiverLogo className="w-[70px] tablet:w-16" />
                  </a>
                </div>

                {!hasSentOTP ? (
                  <div
                    className="tablet:-mt-16 flex-1 flex flex-col justify-center items-center 
                  tablet:items-start space-y-5"
                  >
                    <label
                      className="form-label !font-normal"
                      htmlFor="phoneNumber"
                    >
                      What is your phone number?
                    </label>

                    <div className="w-full tablet:w-[80%] PhoneInput space-y-2">
                      <PhoneInput
                        country="US"
                        placeholder="(213)-460-5490"
                        className={`${
                          errors.phoneNumber ? '!border-river-red' : ''
                        }`}
                        value={values.phoneNumber}
                        onChange={value =>
                          formik.setFieldValue('phoneNumber', value)
                        }
                      />

                      {errors.phoneNumber && (
                        <p className="error-label">{errors.phoneNumber}</p>
                      )}
                    </div>

                    <Button
                      label="Continue"
                      onClick={() => handleSubmit()}
                      disabled={isSendingOTP}
                      loading={isSendingOTP}
                      className="w-[225px] !mt-20 tablet:!mt-16"
                    />
                  </div>
                ) : (
                  <div
                    className="tablet:-mt-16 flex-1 flex flex-col justify-center items-center 
                  tablet:items-start space-y-5"
                  >
                    <label className="form-label !font-normal" htmlFor="otp">
                      Enter verification code
                    </label>

                    <div className="w-full tablet:w-[80%] PhoneInput space-y-2">
                      <div className="flex flex-row space-x-4 items-center justify-center tablet:justify-start">
                        <Input
                          name="pin1"
                          className="!text-center w-[46px]"
                          value={values.pin1}
                          required
                          maxLength={1}
                          onChange={e =>
                            handlePinChange(e.target, 'pin1', formik)
                          }
                          error={Boolean(otpError && !values.pin1)}
                          onFocus={(e: any) => e.target.select()}
                        />
                        <Input
                          name="pin2"
                          className="!text-center w-[46px]"
                          value={values.pin2}
                          required
                          maxLength={1}
                          onChange={e =>
                            handlePinChange(e.target, 'pin2', formik)
                          }
                          error={Boolean(otpError && !values.pin2)}
                          onFocus={(e: any) => e.target.select()}
                        />
                        <Input
                          name="pin3"
                          className="!text-center w-[46px]"
                          value={values.pin3}
                          required
                          maxLength={1}
                          onChange={e =>
                            handlePinChange(e.target, 'pin3', formik)
                          }
                          error={Boolean(otpError && !values.pin3)}
                          onFocus={(e: any) => e.target.select()}
                        />
                        <Input
                          name="pin4"
                          className="!text-center w-[46px]"
                          value={values.pin4}
                          required
                          maxLength={1}
                          onChange={e =>
                            handlePinChange(e.target, 'pin4', formik)
                          }
                          error={Boolean(otpError && !values.pin4)}
                          onFocus={(e: any) => e.target.select()}
                        />
                      </div>

                      {otpError && <p className="error-label">{otpError}</p>}

                      <p className="hintText !mt-3">
                        We&apos;ve sent a 4 digit code to{' '}
                        <span className="">{`***-***-${values.phoneNumber.substring(
                          values.phoneNumber.length - 4,
                        )}`}</span>
                      </p>
                    </div>

                    <Button
                      label="Continue"
                      onClick={() => handleSubmit()}
                      disabled={isVerifyingOTP || isLoadingCompanies}
                      loading={isVerifyingOTP || isLoadingCompanies}
                      className="w-[225px] !mt-20 tablet:!mt-16"
                    />
                  </div>
                )}
              </div>

              <div className="w-full h-[30vh] mt-[68px] tablet:mt-0 tablet:h-screen">
                <img
                  className="w-full h-full object-cover object-center hidden tablet:block"
                  src={WelcomeIntro}
                  alt="welcome"
                />

                <img
                  className="w-full h-full object-cover object-center block tablet:hidden"
                  src={WelcomeIntroMobile}
                  alt="welcome"
                />
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default Auth;
