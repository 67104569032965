import React from 'react';
import { logout, showAlert, useAuth } from '../../context/authContext';
import { useForm } from '../../context/formContext';
import Alert from '../Alert/Alert';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Navbar from '../Navbar/Navbar';

function Layout({ children }: { children: React.ReactNode }) {
  const [{ alert }, dispatch] = useAuth();
  const [{ page }] = useForm();

  return (
    <div className="relative min-h-screen h-auto overflow-auto bg-white flex flex-col">
      <Navbar logout={() => logout()} page={page} />

      <ErrorBoundary>
        <main className="pt-10 flex-auto h-auto">
          <Alert
            show={alert.show}
            type={alert.type}
            message={alert.message}
            onAction={() => showAlert(dispatch)}
            onActionMessage="Close"
          />
          {children}
        </main>
      </ErrorBoundary>
    </div>
  );
}

export default Layout;
