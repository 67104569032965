/* eslint-disable @typescript-eslint/no-use-before-define */
import Cookies from 'js-cookie';
import * as React from 'react';
import { Alert, AuthAction, AuthDispatch, AuthState, AuthUser } from '../types';
import { storage } from '../utils';
import {
  clearCurrentUserOnSentry,
  reportUserIdentityToSentry,
} from '../utils/reportToSentry';

const initialState: AuthState = {
  user: null,
  alert: { message: '', type: 'INFO', show: false },
};

const AuthContext = React.createContext<
  [state: AuthState, dispatch: AuthDispatch] | undefined
>(undefined);

AuthContext.displayName = 'AuthContext';

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'SET_AUTH_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SHOW_ALERT':
      return {
        ...state,
        alert: action.payload,
      };
    default:
      return state;
  }
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  const value = React.useMemo(() => [state, dispatch], [state, dispatch]) as [
    state: AuthState,
    dispatch: AuthDispatch,
  ];

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`\`useAuth\` must be used within a AuthProvider`);
  }
  return context;
}

export function showAlert(
  dispatch: AuthDispatch,
  { show, type, message } = {} as Alert,
  autoDismissTimer = 0,
) {
  dispatch({
    type: 'SHOW_ALERT',
    payload: { show, type, message },
  });

  if (autoDismissTimer) {
    setTimeout(
      () =>
        dispatch({
          type: 'SHOW_ALERT',
          payload: { show: false, type: 'INFO', message: '' },
        }),
      autoDismissTimer,
    );
  }
}

export function logout() {
  storage.remove('_river_employer_temp_form');
  Cookies.remove('_river_employer_tokid');
  clearCurrentUserOnSentry();
  window.location.reload();
}

export function subscribeToExternals(authUser: AuthUser) {
  reportUserIdentityToSentry({
    phoneNumber: authUser.phoneNumber,
    id: authUser.id,
  });
}

export { AuthProvider, useAuth };
