/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Icons from '../../assets/icons';

import { ReactComponent as IconTickCircle } from '../../assets/icons/icon_tick_circle.svg';
import { ReactComponent as IconEligibilityByEmail } from '../../assets/icons/icon_eligibility_by_email.svg';
import { ReactComponent as IconEligibilityByPayroll } from '../../assets/icons/icon_eligibility_by_payroll.svg';
import { ReactComponent as IconWaveEmoji } from '../../assets/icons/icon_wave_emoji.svg';
import { ReactComponent as IconLogout } from '../../assets/icons/icon_logout.svg';
import { ReactComponent as IconInputChecked } from '../../assets/icons/icon_input_checked.svg';
import { ReactComponent as IconTrash } from '../../assets/icons/icon_trash.svg';

type IconProps = {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const allIcons: Record<
  string,
  (props: React.SVGProps<SVGSVGElement>) => JSX.Element | any
> = {
  ...Icons,
  tickCircle: IconTickCircle,
  eligibilityByEmail: IconEligibilityByEmail,
  eligibilityByPayroll: IconEligibilityByPayroll,
  logoutIcon: IconLogout,
  waveEmoji: IconWaveEmoji,
  checked: IconInputChecked,
  trash: IconTrash,
};

function Icon({ id, ...rest }: IconProps) {
  const SelectedIcon = allIcons[id];
  return SelectedIcon ? <SelectedIcon id={id} {...rest} /> : null;
}

Icon.defaultProps = {
  className: '',
  style: undefined,
  onClick: () => false,
};

export default Icon;
