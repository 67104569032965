import React from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import Button from '../../../components/Button/Button';
import { ReactComponent as RiverLogo } from '../../../assets/icons/icon_river_logo.svg';
import Input from '../../../components/Inputs/Input/Input';
import CheckBox from '../../../components/Inputs/CheckBox/CheckBox';
import { useForm, tempSaveForm } from '../../../context/formContext';
import { paths } from '../../../routes';
import FormLayout, {
  submitForm,
} from '../../../components/FormLayout/FormLayout';
import { Form } from '../../../types';
import { env } from '../../../utils';

const PAGE = 5;
const STARTER_CARE_PLAN_COST = env('RIVER_STARTER_CARE_PLAN_COST') || 35;
const COMPLETE_CARE_PLAN_COST = env('RIVER_COMPLETE_CARE_PLAN_COST') || 50;

function FormFive() {
  const [{ form, hasAgreedToTerms }, dispatch] = useForm();
  const [openTerms, setOpenTerms] = React.useState(!hasAgreedToTerms);
  const navigate = useNavigate();

  const validationSchema: Yup.ObjectSchema<Partial<Record<keyof Form, any>>> =
    Yup.object().shape({
      isAFullyCoveredPlan: Yup.boolean().required(),
      amountCoveredByEmployer: Yup.number()
        .transform(value => (Number.isNaN(value) ? undefined : value))
        .when('isAFullyCoveredPlan', {
          is: 'No',
          then: Yup.number().required(),
        }),
      planPaymentOption: Yup.string().when('isAFullyCoveredPlan', {
        is: 'No',
        then: Yup.string().required(),
      }),
    });

  const parseSubmitData = ({
    isAFullyCoveredPlan,
    amountCoveredByEmployer,
    planPaymentOption,
  }: {
    isAFullyCoveredPlan?: boolean;
    amountCoveredByEmployer: number;
    planPaymentOption: string;
  }) => {
    let fullyCoveredStarterCare = false;
    let fullyCoveredCompleteCare = false;
    let amountCoveredByEmployerStarterCare = 0;
    let amountCoveredByEmployerCompleteCare = 0;

    if (isAFullyCoveredPlan) {
      if (form.isOfferingStarterCare && form.isOfferingCompleteCare) {
        fullyCoveredStarterCare = true;
        fullyCoveredCompleteCare = true;
        amountCoveredByEmployerStarterCare = +STARTER_CARE_PLAN_COST; // The (+) is parsing it to a number
        amountCoveredByEmployerCompleteCare = +COMPLETE_CARE_PLAN_COST;
      } else if (form.isOfferingStarterCare) {
        fullyCoveredStarterCare = true;
        amountCoveredByEmployerStarterCare = +STARTER_CARE_PLAN_COST;
      } else if (form.isOfferingCompleteCare) {
        fullyCoveredCompleteCare = true;
        amountCoveredByEmployerCompleteCare = +COMPLETE_CARE_PLAN_COST;
      }
    } else if (form.isOfferingStarterCare && form.isOfferingCompleteCare) {
      amountCoveredByEmployerStarterCare = amountCoveredByEmployer || 0;
      amountCoveredByEmployerCompleteCare = amountCoveredByEmployer || 0;
    } else if (form.isOfferingStarterCare) {
      amountCoveredByEmployerStarterCare = amountCoveredByEmployer || 0;
    } else if (form.isOfferingCompleteCare) {
      amountCoveredByEmployerCompleteCare = amountCoveredByEmployer || 0;
    }

    return {
      ...form,
      fullyCoveredStarterCare,
      fullyCoveredCompleteCare,
      amountCoveredByEmployerStarterCare,
      amountCoveredByEmployerCompleteCare,
      planPaymentOption: isAFullyCoveredPlan
        ? 'Fully Sponsored'
        : planPaymentOption,
    };
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
        initialValues={{
          isAFullyCoveredPlan: form.planPaymentOption
            ? form.planPaymentOption === 'Fully Sponsored'
            : undefined,
          amountCoveredByEmployer:
            form.amountCoveredByEmployerStarterCare === undefined &&
            form.amountCoveredByEmployerCompleteCare === undefined
              ? NaN
              : form.amountCoveredByEmployerStarterCare || 0,
          planPaymentOption: form.planPaymentOption,
        }}
        validationSchema={validationSchema}
        onSubmit={data => {
          tempSaveForm(dispatch, parseSubmitData(data));
          navigate(paths.FORM_SIX_URL_PATH);
        }}
      >
        {formik => {
          const { values, handleChange, errors, setFieldValue } = formik;

          return (
            <FormLayout
              continueHandler={async () => {
                await submitForm(formik);
              }}
              page={PAGE}
              previous={paths.FORM_FOUR_URL_PATH}
            >
              <div className="space-y-6 max-w-[720px] mx-auto px-8 pt-0 tablet:pt-7 pb-7">
                <div className="space-y-3">
                  <label className="form-label" htmlFor="isAFullyCoveredPlan">
                    Is the employer covering 100% of the cost of the plan?
                  </label>
                  <div className="grid tablet:grid-cols-2 grid-cols-1 gap-4">
                    <label
                      htmlFor="yesFullCover"
                      className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                        errors.isAFullyCoveredPlan &&
                        !values.isAFullyCoveredPlan
                          ? '!border-river-red'
                          : ''
                      }`}
                    >
                      <CheckBox
                        id="yesFullCover"
                        name="isAFullyCoveredPlan"
                        value="Yes"
                        checked={
                          values.isAFullyCoveredPlan === undefined
                            ? false
                            : !!values.isAFullyCoveredPlan
                        }
                        onChange={event => {
                          setFieldValue(
                            'isAFullyCoveredPlan',
                            !!event.target.checked,
                          );
                        }}
                        className="align-middle cursor-pointer"
                      />{' '}
                      <span className="align-middle text-[16px] ml-2 font-normal">
                        Yes
                      </span>
                    </label>
                    <label
                      htmlFor="noFullCover"
                      className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                        errors.isAFullyCoveredPlan &&
                        !values.isAFullyCoveredPlan
                          ? '!border-river-red'
                          : ''
                      }`}
                    >
                      <CheckBox
                        id="noFullCover"
                        name="isAFullyCoveredPlan"
                        value="No"
                        checked={
                          values.isAFullyCoveredPlan === undefined
                            ? false
                            : !values.isAFullyCoveredPlan
                        }
                        onChange={event => {
                          setFieldValue(
                            'isAFullyCoveredPlan',
                            !event.target.checked,
                          );
                        }}
                        className="align-middle cursor-pointer"
                      />{' '}
                      <span className="align-middle text-[16px] ml-2 font-normal">
                        No
                      </span>
                    </label>
                  </div>
                </div>

                {values.isAFullyCoveredPlan === false ? (
                  <>
                    <div className="space-y-3">
                      <label
                        className="form-label"
                        htmlFor="amountCoveredByEmployer"
                      >
                        How much is the employer contributing towards the plan?
                      </label>

                      <Input
                        name="amountCoveredByEmployer"
                        id="amountCoveredByEmployer"
                        type="number"
                        value={
                          Number.isNaN(values.amountCoveredByEmployer)
                            ? ''
                            : `${values.amountCoveredByEmployer}`
                        }
                        onChange={handleChange}
                        placeholder="$20"
                        onKeyPress={(event: any) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        error={Boolean(errors.amountCoveredByEmployer)}
                      />
                    </div>

                    <div className="space-y-3">
                      <label className="form-label" htmlFor="planPaymentOption">
                        How will employees pay for the plan?
                      </label>

                      <div className="grid tablet:grid-cols-2 grid-cols-1 gap-4">
                        <label
                          htmlFor="payroll"
                          className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                            errors.planPaymentOption &&
                            !values.planPaymentOption
                              ? '!border-river-red'
                              : ''
                          }`}
                        >
                          <CheckBox
                            id="payroll"
                            name="planPaymentOption"
                            value="Payroll Deduction"
                            checked={
                              values.planPaymentOption === 'Payroll Deduction'
                            }
                            onChange={event => {
                              setFieldValue(
                                'planPaymentOption',
                                event.target.checked ? 'Payroll Deduction' : '',
                              );
                            }}
                            className="align-middle cursor-pointer"
                          />{' '}
                          <span className="align-middle text-[16px] ml-2 font-normal">
                            Payroll Deduction
                          </span>
                        </label>

                        <label
                          htmlFor="credit"
                          className={`cursor-pointer checkbox-form-input transition-all linear h-fit !py-[0.47rem] ${
                            errors.planPaymentOption &&
                            !values.planPaymentOption
                              ? '!border-river-red'
                              : ''
                          }`}
                        >
                          <CheckBox
                            id="credit"
                            name="planPaymentOption"
                            value="Credit Card"
                            checked={values.planPaymentOption === 'Credit Card'}
                            onChange={event => {
                              setFieldValue(
                                'planPaymentOption',
                                event.target.checked ? 'Credit Card' : '',
                              );
                            }}
                            className="align-middle cursor-pointer"
                          />{' '}
                          <span className="align-middle text-[16px] ml-2 font-normal">
                            Credit Card
                          </span>
                        </label>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </FormLayout>
          );
        }}
      </Formik>

      <Transition.Root show={openTerms} as={React.Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0" onClose={setOpenTerms}>
          <div className="flex flex-col items-center justify-end tablet:justify-center min-h-screen pt-4 px-4 pb-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block tablet:w-[70%] w-full">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setOpenTerms(false)}
                    className="inline-block bg-white rounded-full px-4 pt-4 pb-4 text-left 
                    overflow-hidden shadow-xl outline-none transform transition-all w-fit"
                  >
                    <Icon
                      className="w-4 h-4 fill-river-black text-river-black"
                      id="close"
                    />
                  </button>
                </div>

                <div className="inline-block relative bg-white rounded-md text-left shadow-xl transform transition-all my-4 w-full">
                  <div
                    style={{ bottom: 25 }}
                    className="absolute p-5 w-full flex justify-center items-center"
                  >
                    <Button
                      label="I agree"
                      onClick={() => {
                        setOpenTerms(false);
                        dispatch({
                          type: 'SET_HAS_AGREED_TO_TERMS',
                          payload: true,
                        });
                      }}
                      className="py-[14px] px-[70px]"
                    />
                  </div>

                  <div className="pt-8">
                    <div className="px-8 tablet:px-14">
                      <RiverLogo className="w-20 tablet:w-16" />
                    </div>

                    <div className="mt-6">
                      <div className="px-8 tablet:px-14">
                        <h2 className="text-[22px] leading-[36px] tracking-[0.03em]">
                          Terms & Conditions agreement
                        </h2>
                      </div>

                      <div className="mt-6 terms-max-height overflow-y-scroll overflow-x-hidden">
                        <p className="text-sm leading-relaxed font-light px-8 tablet:px-14 pb-28">
                          This Master Services Agreement (“
                          <span className="font-semibold">Agreement</span>”)
                          starts upon completion of the online employer account
                          creation process between Mountain Health Technologies,
                          Inc., located at 1910 Pacific Ave #20000, Dallas
                          Texas, 75201 (“
                          <span className="font-semibold">Mountain</span>
                          ”), and [{form.name}], Incorporated with an office at
                          [{form.addressStreet}] (“
                          <span className="font-semibold">Customer</span>”).
                          This Agreement sets forth the terms and conditions
                          applicable to Customer’s use of the Products to be
                          provided by Mountain under any Statement of Work
                          executed by both parties. <br /> <br />
                          <span className="font-semibold">
                            1. DEFINITIONS
                          </span>{' '}
                          <br />
                          <br />“
                          <span className="font-semibold">
                            Authorized Users
                          </span>
                          ” means any individuals authorized to use the Products
                          by Customer as specified in a Statement of Work.
                          Authorized users may be employees or contractors of
                          Customer. <br /> <br />“
                          <span className="font-semibold">
                            Customer Content
                          </span>
                          ” means any materials or other information which is
                          made available to Mountain by Customer (or on
                          Customer’s behalf), including but not limited to
                          trademarks, trade names and service marks for any
                          customization of the Products for the Customer. <br />{' '}
                          <br />“
                          <span className="font-semibold">Customer Data</span>”
                          means the information obtained through Customer’s and
                          its Authorized User’s use of the Products, including
                          any information provided by Customer about Authorized
                          Users. <br /> <br />“
                          <span className="font-semibold">
                            Mountain Technology
                          </span>
                          ” means all of Mountain’s proprietary technology
                          (including data, text, video, photos, audio, software,
                          processes, algorithms, API’s, user interfaces,
                          know-how, techniques, designs and other tangible or
                          intangible materials or information) made available to
                          Customer and Authorized Users by Mountain through the
                          Products. <br /> <br />“
                          <span className="font-semibold">
                            Intellectual Property Rights
                          </span>
                          ” means patents, copyrights, trademarks, service
                          marks, trade secrets and other intellectual property
                          rights (registered or unregistered). <br /> <br />“
                          <span className="font-semibold">Malicious Code</span>”
                          means viruses, worms, time bombs, Trojan horses and
                          other harmful or malicious code, files, scripts,
                          agents or programs. <br /> <br />“
                          <span className="font-semibold">
                            Statement of Work
                          </span>
                          ” means any document used to place an order hereunder,
                          including addenda and supplements thereto, the terms
                          of which are agreed to by Mountain and Customer in
                          writing. Statements of Work shall be deemed
                          incorporated herein by reference. The initial
                          Statement of Work is attached hereto as Exhibit A.{' '}
                          <br /> <br />“
                          <span className="font-semibold">Products</span>” means
                          Mountain’s women’s health, primary care and mental
                          health services developed, operated and maintained by
                          Mountain and accessible via http://www.helloriver.com
                          (or other designated website) and mobile devices, to
                          which Customer and Authorized Users are being granted
                          access under this Agreement. <br /> <br />
                          <span className="font-semibold">2. PRODUCTS</span>
                          <br /> <br />
                          <span className="font-semibold">
                            2.1. Provision of the Products.
                          </span>{' '}
                          Subject to the terms and conditions of this Agreement,
                          Mountain hereby grants Customer a non- exclusive,
                          non-transferable, non-assignable limited license to
                          use the Products solely for Customer’s own business
                          purposes as set forth in each fully executed Statement
                          of Work. All rights not expressly granted to Customer
                          are reserved by Mountain. Mountain reserves the right
                          to make changes, modifications and enhancements to the
                          Products from time to time. Customer agrees that its
                          purchases hereunder are neither contingent on the
                          delivery of any future functionality or features nor
                          dependent on any oral or written public comments made
                          by Mountain regarding future functionality or
                          features.
                          <br /> <br />
                          <span className="font-semibold">
                            2.2. Authorized User Accounts.
                          </span>{' '}
                          In order to use the Products, each Authorized User
                          will need to create an account through the Products
                          and accept the Mountain’s Terms & Conditions located
                          at https://helloriver.com/terms.html (the “Authorized
                          User Terms & Conditions”). Such Authorized User Terms
                          & Conditions may be modified by
                          <br /> <br />
                          Mountain from time to time with or without notice. Any
                          Authorized User violating the Authorized User Terms &
                          Conditions may have the Authorized User’s account and
                          access to the Products suspended or terminated as
                          provided therein.
                          <br /> <br />
                          <span className="font-semibold">
                            2.3. Mountain Responsibilities.
                          </span>{' '}
                          Mountain will: (i) use commercially reasonable efforts
                          to make the Products available 24 hours a day, 7 days
                          a week, except for (a) planned downtime, or (b) any
                          unavailability caused by circumstances beyond
                          Mountain’s reasonable control, including without
                          limitation, acts of God, acts of government, floods,
                          fires, earthquakes, civil unrest, acts of terror,
                          strikes or other labor problems (other than those
                          involving Mountain employees), Internet service
                          provider failures or delays, or denial of service
                          attacks; (ii) provide the Products only in accordance
                          with applicable laws and government regulations; and
                          (iii) ensure that the servers and other hardware
                          related to the Products are maintained in a secure
                          environment, exercising a standard of care customary
                          in the industry. (iv) Provide quality care to
                          Customer’s employees during clinic hours as defined by
                          Mountain.
                          <br /> <br />
                          <span className="font-semibold">
                            2.4. Customer Responsibilities.
                          </span>{' '}
                          Customer shall: (i) use commercially reasonable
                          efforts to prevent unauthorized access to or use of
                          the Products, and notify Mountain promptly should
                          Customer become aware of any such unauthorized access
                          or use; and (ii) use the Products only in accordance
                          with its intended purpose and applicable laws and
                          government regulations. Customer shall not: (a) make
                          the Products available to anyone other than its
                          Authorized Users; (b) sell, resell, rent or lease the
                          Products; (c) knowingly use the Products to store or
                          transmit infringing, libelous, or otherwise unlawful
                          or tortuous material, or to store or transmit material
                          in violation of third- party privacy rights; (d)
                          knowingly use the Products to store or transmit
                          Malicious Code; (e) knowingly interfere with or
                          disrupt the integrity or performance of the Products
                          or third-party data contained therein; or (f) attempt
                          to gain unauthorized access to the Products or its
                          related systems or networks.
                          <br /> <br />
                          <span className="font-semibold">
                            2.5. Customer Data.
                          </span>{' '}
                          All Customer Data shall be owned by Customer and may
                          only be used by Mountain in order (i) to deliver and
                          optimize the Products and (ii) to develop behavioral
                          insights about use of the Products in a such a way
                          that does not identify the Customer or any Authorized
                          User. Given the health and wellness nature of the
                          Products provided, Mountain will maintain all Customer
                          Data in confidence, including without limitation any
                          personal identifiable information regarding Authorized
                          Users.
                          <br /> <br />
                          <span className="font-semibold">
                            3. FEES AND PAYMENT
                          </span>
                          <br /> <br />
                          <span className="font-semibold">3.1. Fees.</span>{' '}
                          Customer shall pay all fees specified in all
                          Statements of Work hereunder. Except as otherwise
                          specified herein or in a Statement of Work (i) fees
                          are based on Products purchased and not actual usage;
                          (ii) payment obligations are non-cancelable; and (iii)
                          fees paid are non-refundable.
                          <br /> <br />
                          <span className="font-semibold">
                            3.2. Invoicing and Payment.
                          </span>{' '}
                          Mountain shall invoice Customer in accordance with the
                          relevant Statement of Work. Unless otherwise stated in
                          the Statement of Work, invoiced charges are due net 30
                          days from the invoice date. Customer is responsible
                          for providing complete and accurate billing and
                          contact information to Mountain and notifying Mountain
                          of any changes to such information.
                          <br /> <br />
                          <span className="font-semibold">
                            3.3. Overdue Charges.
                          </span>{' '}
                          If Customer fails to make payment within the terms of
                          the Statement of Work, Mountain may, upon 15 days’
                          notice and provided non-payment is not cured within
                          such period, suspend its provision of the Products and
                          any other Products under this Agreement until payment
                          is made. Customer agrees to pay all reasonable legal
                          fees and other costs of collection incurred by
                          Mountain in connection with Customer’s breach of its
                          payment obligations hereunder.
                          <br /> <br />
                          <span className="font-semibold">
                            3.4. Taxes.
                          </span>{' '}
                          Customer is responsible for and shall pay any federal,
                          state or local sales, use or value-added taxes based
                          on Products performed or payments made hereunder.
                          <br /> <br />
                          <span className="font-semibold">
                            4. TERM AND TERMINATION
                          </span>
                          <br /> <br />
                          <span className="font-semibold">
                            4.1. Term of Agreement.
                          </span>{' '}
                          This Agreement commences on the Effective Date and
                          continues until terminated by written notice of either
                          party pursuant to Section 4.2 or until all Products
                          from executed Statements of Work have been provided,
                          whichever is later.
                          <br /> <br />
                          <span className="font-semibold">
                            4.2. Termination for Cause.
                          </span>{' '}
                          This Agreement may be terminated by either party: (i)
                          if the other party is in material breach of this
                          Agreement and the breach is not cured within 30 days
                          after written notice of the breach; or (ii) if the
                          other party becomes the subject of a petition in
                          bankruptcy or any other proceeding relating to
                          insolvency, receivership, liquidation or assignment
                          for the benefit of creditors.
                          <br /> <br />
                          <span className="font-semibold">
                            4.3. Refund or Payment upon Termination.
                          </span>{' '}
                          Upon any termination for cause by Customer, Mountain
                          shall refund Customer any prepaid fees covering the
                          remainder of the term of all Statements of Work after
                          the effective date of termination. Upon any
                          termination for cause by Mountain, Customer shall pay
                          any unpaid fees covering the remainder of the term of
                          all Statements of Work after the effective date of
                          termination. In no event shall any termination relieve
                          Customer of the obligation to pay any fees payable to
                          Mountain for the period prior to the effective date of
                          termination.
                          <br /> <br />
                          <span className="font-semibold">
                            4.4. Surviving Provisions.
                          </span>{' '}
                          Sections 1 (Definitions), 4 (Termination), 5
                          (Proprietary Rights), 6 (Confidentiality), 7
                          (Warranties and Disclaimers), 8 (Mutual
                          Indemnification), 9 (Limitation of Liability), 10
                          (Publicity) and 11 (General) shall survive termination
                          of this Agreement, together with any payment
                          obligations accrued prior to termination and any other
                          provisions which by their plain meaning are intended
                          to survive.
                          <br /> <br />
                          <span className="font-semibold">
                            5. PROPRIETARY RIGHTS
                          </span>
                          <br /> <br />
                          <span className="font-semibold">
                            5.1. Reservation of Rights.
                          </span>{' '}
                          Subject to the limited rights expressly granted
                          hereunder, Mountain and its licensors reserve all
                          right, title and interest in and to the Products,
                          including the Mountain Technology and all related
                          Intellectual Property Rights. No rights are granted to
                          Customer hereunder other than as expressly set forth
                          herein. Customer shall not: (i) permit any third party
                          to access the Products except as permitted hereunder;
                          (ii) create derivate works based on the Products;
                          (iii) copy, frame or mirror any part or content of the
                          Products; (iv) reverse engineer the Products; or (v)
                          access the Products in order to (a) build a
                          competitive product or service or (b) copy any
                          features, functions or graphics of the Products.
                          <br /> <br />
                          <span className="font-semibold">
                            5.2. Customer Content and Customer Data.
                          </span>{' '}
                          Subject to the limited rights granted by Customer
                          hereunder, Customer reserves all right, title and
                          interest in and to the Customer Content and the
                          Customer Data, including all related Intellectual
                          Property Rights.
                          <br /> <br />
                          <span className="font-semibold">
                            5.3. Suggestions.
                          </span>{' '}
                          Customer hereby grants Mountain a royalty-free,
                          worldwide, irrevocable, transferable, perpetual
                          license to use and incorporate into the Products or
                          other Mountain Products or products any suggestions,
                          enhancement requests, recommendations or other
                          feedback provided by Customer or its employees or
                          agents relating to the Products.
                          <br /> <br />
                          <span className="font-semibold">
                            6. CONFIDENTIALITY
                          </span>
                          <br /> <br />
                          Each party may have access to the other party’s
                          information, which shall be deemed confidential
                          information if identified as such by the disclosing
                          party or if the information by its nature is normally
                          and reasonably considered confidential, such as
                          information regarding products, pricing, methodology,
                          research, customers, business partners, business plans
                          and any information which provides a competitive
                          advantage. The receiving party shall protect the
                          disclosing party’s confidential information with the
                          same degree of care it uses for the receiving party’s
                          own confidential information (and at least a
                          reasonable degree of care), shall use the information
                          only to carry out this Agreement, and shall disclose
                          the information only to the receiving party’s
                          employees (or agents bound by similar confidentiality
                          obligations) with a need to know for that purpose.
                          Confidential information shall remain the property of
                          the disclosing party and shall be destroyed upon
                          request. Notwithstanding the above, the receiving
                          party shall be required to accelerate the destruction
                          of any archival back-up tapes created in the ordinary
                          course of business, even if such archival back-up
                          tapes contain confidential information. Information
                          shall not be deemed confidential information if it:
                          (i) is disclosed by the disclosing party to others
                          without restriction on use and disclosure; (ii)
                          becomes known to the receiving party without
                          restriction from a third party who is not in breach of
                          a confidentiality agreement with the disclosing party;
                          (iii) is already known by the receiving party at the
                          time of disclosure; or (iv) is independently developed
                          by the receiving party without any reliance on the
                          confidential information of the disclosing party.
                          Confidential information may be disclosed to the
                          extent required by applicable law, provided the
                          disclosing party is given reasonable advance notice of
                          such disclosure. The terms of this Agreement shall be
                          considered the confidential information of each party,
                          but the parties may disclose their working
                          relationship in the normal course of doing business.
                          For the avoidance of doubt, any information related to
                          the commercial aspects of this Agreement, including
                          but not limited to; information on pricing, product
                          delivery, marketing, and product functionality, shall
                          be considered confidential information pursuant to
                          this clause.
                          <br /> <br />
                          <span className="font-semibold">
                            7. WARRANTIES AND DISCLAIMERS
                          </span>
                          <br /> <br />
                          <span className="font-semibold">
                            7.1. Warranties.
                          </span>{' '}
                          Mountain represents and warrants that (i) it has all
                          necessary rights and authority to execute and deliver
                          this Agreement and perform its obligations hereunder
                          and to grant the rights granted under this Agreement
                          to Customer; (ii) nothing contained in this Agreement
                          or in the performance of this Agreement will place
                          Mountain in breach of any other material contract or
                          obligation; and (iii) the Products and the
                          Professional Products will be provided in accordance
                          with all applicable laws and government regulations
                          and conform to or exceed the standards generally
                          observed in the industry for similar Products.
                          <br /> <br />
                          <span className="font-semibold">
                            7.2. Disclaimer.
                          </span>{' '}
                          EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, THE
                          PRODUCTS AND ANY PROFESSIONAL PRODUCTS PROVIDED BY
                          MOUNTAIN HEREUNDER ARE PROVIDED “AS IS”, AND MOUNTAIN
                          DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR
                          STATUTORY, INCLUDING BUT NOT LIMITED TO IMPLIED
                          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                          PARTICULAR PURPOSE.
                          <br /> <br />
                          <span className="font-semibold">
                            8. MUTUAL INDEMNIFICATION
                          </span>
                          <br /> <br />
                          <span className="font-semibold">
                            8.1. Indemnification by Mountain.
                          </span>{' '}
                          Mountain shall indemnify and hold harmless Customer
                          and its officers, directors, employees and agents from
                          and against all claims, damages, losses and expenses
                          (including reasonable attorneys’ fees) arising out of
                          any claim by a third party to the extent such claim
                          alleges that the use of the Products by Customer and
                          its Authorized Users in accordance with its intended
                          purpose or any material created, prepared, or
                          developed by Mountain and delivered through the
                          Products infringes any patent, copyright, trademark,
                          service mark or trade secret rights. If Mountain
                          believes the Products are or may become the subject of
                          a claim of infringement, Mountain may, at its option
                          and expense, procure for itself and/or Customer and
                          its Authorized Users the right to continue to use the
                          Products, or modify or replace the Products to make
                          the Products non-infringing and functionally
                          equivalent. If
                          <br /> <br />
                          Mountain concludes that neither of these alternatives
                          is reasonably available, it may terminate this
                          Agreement upon thirty (30) days written notice and
                          refund any pre-paid fees covering the remainder of the
                          term of this Agreement after such termination.
                          <br /> <br />
                          <span className="font-semibold">
                            8.2. Indemnification by Customer.
                          </span>{' '}
                          Customer shall indemnify and hold harmless Mountain
                          and its officers, directors, employees and agents from
                          and against all claims, damages, losses and expenses
                          (including reasonable attorneys’ fees), arising out of
                          any claim by a third party to the extent such claim is
                          based on the Customer Content (including but not
                          limited to use of the Customer Content as permitted
                          hereunder) or on Customer’s use of the Products other
                          than in accordance with this Agreement.
                          <br /> <br />
                          <span className="font-semibold">
                            8.3. Procedures.
                          </span>{' '}
                          The party seeking indemnification shall provide
                          detailed written notice to the indemnifying party
                          promptly after learning of the claim, and the
                          indemnifying party shall not be obligated to indemnify
                          to the extent it is materially prejudiced by any delay
                          in such notice. The indemnifying party shall have the
                          right to assume control of the defense and settlement
                          of the claim, and the indemnified party shall provide
                          reasonable assistance at the indemnifying party’s
                          reasonable expense, provided that the indemnified
                          party shall not be obligated to participate in any
                          settlement pursuant to which the indemnified party is
                          required to admit liability or pay any amount other
                          than amounts concurrently reimbursed by the
                          indemnifying party.
                          <br /> <br />
                          <span className="font-semibold">
                            9. LIMITATION OF LIABILITY
                          </span>
                          <br /> <br />
                          NEITHER PARTY SHALL BE LIABLE FOR ANY CONSEQUENTIAL,
                          INCIDENTAL, INDIRECT OR SPECIAL DAMAGES INCURRED BY
                          THE OTHER PARTY, INCLUDING BUT NOT LIMITED TO LOSS OF
                          DATA, USE OR PROFIT, EVEN IF ADVISED IN ADVANCE OF THE
                          POSSIBILITY OF SUCH DAMAGES. EACH PARTY’S LIABILITY
                          WITH RESPECT TO THIS AGREEMENT (WHETHER IN CONTRACT,
                          TORT OR OTHERWISE) IS LIMITED TO AN AMOUNT EQUAL TO
                          THE AMOUNTS PAID OR PAYABLE BY CUSTOMER UNDER THIS
                          AGREEMENT DURING THE 12 MONTHS PRIOR TO THE DATE OF
                          THE INITIAL EVENT CAUSING OR RESULTING IN SUCH
                          LIABILITY. THE FOREGOING LIMITATIONS SHALL NOT APPLY
                          TO CUSTOMER’S PAYMENT OBLIGATIONS UNDER SECTIONS 3 OR
                          4.
                          <br /> <br />
                          <span className="font-semibold">10. PUBLICITY</span>
                          <br /> <br />
                          Customer shall not externally publish and/or promote
                          the existence or nature of its working relationship
                          with Mountain for any purposes without the prior
                          written consent of Mountain. Notwithstanding the
                          foregoing, Customer shall have the right to display
                          and/or reference the name, logo, or trademarks of
                          Mountain with respect to services provided to Customer
                          by Mountain.
                          <br /> <br />
                          <span className="font-semibold">11. GENERAL</span>
                          <br /> <br />
                          <span className="font-semibold">
                            11.1. Independent Contractor.
                          </span>{' '}
                          Nothing in this Agreement shall create a joint
                          venture, partnership, employment or agency
                          relationship between Customer and Mountain or
                          Mountain’s employees or contractors. Neither party is
                          authorized by this Agreement to represent, bind,
                          obligate or contract on behalf of the other.
                          <br /> <br />
                          <span className="font-semibold">
                            11.2. Notices.
                          </span>{' '}
                          Any notice, request or communication required or
                          permitted to be given under this Agreement shall be in
                          writing and shall be effective upon the earliest of:
                          (i) actual receipt by the other party; or (ii) two (2)
                          business days after deposit with a nationally
                          recognized overnight courier service, and addressed in
                          each case to the party at the corresponding address
                          first set forth above. Either party may change its
                          notice address by notice in accordance with this
                          paragraph.
                          <br /> <br />
                          <span className="font-semibold">
                            11.3. Entire Agreement; Amendment; Waiver.
                          </span>{' '}
                          With respect to its subject matter, this Agreement and
                          the Statement(s) of Work represents the parties’
                          entire agreement and supersedes all prior agreements,
                          understandings and representations, written or oral,
                          between the parties. This Agreement may be executed
                          and delivered in two or more counterparts and with
                          electronic or facsimile signatures, and may not be
                          amended except by a writing signed by the party to be
                          bound. The failure of a party to require performance
                          of any provision of this Agreement shall in no manner
                          affect its right to enforce the provision.
                          <br /> <br />
                          <span className="font-semibold">
                            11.4. Injunctive Relief.
                          </span>{' '}
                          Either party may seek to enforce its rights hereunder
                          with respect to the protection of its confidential
                          information or intellectual property through temporary
                          or permanent injunctive relief, which shall be in
                          addition to any other available relief and which shall
                          not require a bond or security.
                          <br /> <br />
                          <span className="font-semibold">
                            11.5. Severability.
                          </span>{' '}
                          Any provision of this Agreement which is held invalid
                          or unenforceable in any jurisdiction shall, as to that
                          jurisdiction, be ineffective only to the extent of
                          such invalidity or unenforceability and without
                          rendering invalid or unenforceable the remainder of
                          this Agreement or affecting the validity or
                          enforceability of any of the provisions of this
                          Agreement in any other jurisdiction, and the court or
                          tribunal so holding shall be empowered to substitute,
                          to the extent enforceable, provisions similar to said
                          provision, or other provisions, so as to provide to
                          the parties the benefits intended by said provision to
                          the fullest extent permitted by applicable law.
                          <br /> <br />
                          <span className="font-semibold">
                            11.6. Assignment; No Third Party Beneficiaries.
                          </span>{' '}
                          Except as expressly stated otherwise herein, neither
                          party may assign or transfer (including by operation
                          of law) any rights or obligations under this Agreement
                          without the written consent of the other party, except
                          that either party may, without such consent, assign or
                          transfer this Agreement to a successor to the business
                          of such party by merger, sale of assets or otherwise.
                          Any assignment or transfer, or attempted assignment or
                          transfer, in violation of this Agreement is void ab
                          initio. This Agreement is not intended to confer any
                          rights or remedies upon anyone other than the parties
                          hereto.
                          <br /> <br />
                          <span className="font-semibold">
                            11.7. Governing Law; Jurisdiction.
                          </span>{' '}
                          This Agreement shall be construed in accordance with
                          Texas law, without regard to that state’s conflict of
                          law principles. Any proceeding relating to this
                          Agreement or the subject matter hereof shall be
                          brought only in federal or state courts located in
                          Dallas, Texas and each party hereby generally and
                          unconditionally submits to and accepts the
                          jurisdiction of such courts.
                          <br /> <br />
                          <span className="font-semibold">
                            11.8 Arbitration.
                          </span>{' '}
                          Customer and Mountain agree that any dispute relating
                          to this Agreement or Customer&apos;s use of the
                          Products or Mountain Technology may be resolved by
                          arbitration at the sole discretion of Mountain, in
                          which case Customer waives any right to participate in
                          a class-action lawsuit or class-wide arbitration.
                          Arbitration will be initiated through the American
                          Arbitration Association (&apos;&apos;AAA&apos;&apos;).
                          If the AAA is not available to arbitrate, the parties
                          will select an alternative arbitral forum. All costs
                          associated with arbitration are to be split evenly
                          between the parties. The arbitrator will decide the
                          jurisdiction of the arbitrator and the rights and
                          liabilities, if any, of Customer and Mountain. The
                          arbitrator will have the authority to award all
                          remedies available under applicable law, the arbitral
                          forums rules, and the terms of arbitration. The award
                          of the arbitrator is final and binding upon the
                          parties.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default FormFive;
