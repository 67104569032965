import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Icon from '../../Icon/Icon';

interface ErrorModalProps {
  open: boolean;
  deleting: boolean;
  deleteText: string;
  onClose: () => void;
  onDelete: () => void;
}

function DeletePrompt({
  open,
  deleting,
  deleteText,
  onClose,
  onDelete,
}: ErrorModalProps) {
  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-8 text-center tablet:items-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95"
            enterTo="opacity-100 translate-y-0 mobile:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 mobile:scale-100"
            leaveTo="opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95"
          >
            <div
              className="flex flex-col w-full justify-center items-center tablet:max-w-[420px] 
              transform transition-all space-y-6"
            >
              <button
                type="button"
                onClick={onClose}
                className="w-[35px] h-[35px] bg-white rounded-full flex items-center justify-center self-end"
              >
                <Icon id="close" className="w-3 h-3" />
              </button>
              <div
                className="inline-block relative align-bottom bg-white rounded-3xl w-full px-8 pt-10 pb-10
              text-left overflow-hidden shadow-xl"
              >
                <div className="text-center">
                  <p>
                    Are you sure you want to remove{' '}
                    <strong>{deleteText}</strong> from your employee list?
                  </p>
                  <span className="text-river-deep-orange text-[13px] mt-1 block">
                    The employee will be removed in 24 hours
                  </span>
                  <div className="flex flex-row justify-between items-center mt-7 space-x-5">
                    <button
                      onClick={onClose}
                      type="button"
                      className="bg-river-blue text-white py-[10px] tablet:py-[7px] px-8 text-sm rounded-md mobile:capitalize"
                    >
                      <span className="mobile:hidden">No, </span>cancel
                    </button>
                    <button
                      onClick={onDelete}
                      type="button"
                      disabled={deleting}
                      className="bg-river-red text-white py-[10px] tablet:py-[7px] 
                      px-8 text-sm rounded-md mobile:capitalize disabled:text-gray-300 disabled:opacity-50"
                    >
                      {!deleting ? (
                        <>
                          <span className="mobile:hidden">Yes, </span>remove
                        </>
                      ) : (
                        <span className="text-[14px]">Please wait...</span>
                      )}
                      {deleting && (
                        <Icon
                          id="loader"
                          className="w-5 h-5 ml-1 inline-flex"
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DeletePrompt;
