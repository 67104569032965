import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { AuthProvider, showAlert, useAuth } from './context/authContext';
import routes, { paths } from './routes';
import { getCookie } from './utils';
import { FormProvider } from './context/formContext';
import Layout from './components/Layout/Layout';
import NotFound from './pages/NotFound/NotFound';
import { HTTPError } from './utils/http';
import { getAuthUser } from './api';

function Redirect({ children }: { children: JSX.Element }) {
  const token = getCookie('_river_employer_tokid');

  if (token) return <Navigate to={paths.HOME_URL_PATH} replace />;

  return children;
}

function AuthRoute({ children }: { children: JSX.Element }) {
  const [, dispatch] = useAuth();
  const token = getCookie('_river_employer_tokid');
  const location = useLocation();

  const { isLoading, data: user } = useQuery(['user'], getAuthUser, {
    retry: 1,
    onSuccess: data => {
      dispatch({ type: 'SET_AUTH_USER', payload: data });
    },
    onError: (fndError: HTTPError) => {
      showAlert(dispatch, {
        show: true,
        type: 'ERROR',
        message: fndError.message,
      });
    },
  });

  if (!token) {
    if (!window.sessionStorage.getItem('_river_employer_started')) {
      return (
        <Navigate
          to={paths.INTRO_URL_PATH}
          state={{ from: location }}
          replace
        />
      );
    }
    return (
      <Navigate to={paths.AUTH_URL_PATH} state={{ from: location }} replace />
    );
  }

  if (isLoading && !user) {
    return <div>Please wait...</div>;
  }

  return children;
}

function App() {
  const [isHardLoading, setHardLoading] = React.useState(true);

  React.useEffect(() => {
    (async function fakeHardLoadRequest() {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise(resolve => setTimeout(() => resolve(null), 2000));
    })().then(() => {
      const el = document.querySelector('.pre-loader');
      if (el) {
        el.remove();
        setHardLoading(fndIsHardLoading => !fndIsHardLoading);
      }
    });
  }, []);

  if (isHardLoading) {
    return null;
  }

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {routes.map(route => {
            const Component = route.component;

            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  !route.isPrivate ? (
                    <Redirect>
                      <Component />
                    </Redirect>
                  ) : (
                    <AuthRoute>
                      <FormProvider>
                        <Layout>
                          <Component />
                        </Layout>
                      </FormProvider>
                    </AuthRoute>
                  )
                }
              />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
