import React, { ChangeEvent, FocusEvent } from 'react';

type InputType =
  | 'text'
  | 'email'
  | 'url'
  | 'password'
  | 'number'
  | 'search'
  | 'tel';

export type InputProps = {
  name: string;
  className?: string;
  value?: string;
  id?: string;
  type?: InputType;
  disabled?: boolean;
  error?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  [x: string]: any;
};

function Input({
  className,
  type,
  name,
  id,
  disabled,
  error,
  value,
  onChange,
  ...rest
}: InputProps) {
  return (
    <input
      {...rest}
      className={`${className} ${
        error ? '!border-river-red' : ''
      } disabled:border-[#dfe5eb] disabled:bg-[#f5f7fa]`}
      type={type}
      name={name}
      aria-label={name}
      id={id}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  );
}

Input.defaultProps = {
  id: '',
  className: '',
  type: 'text',
  value: undefined,
  disabled: false,
  error: false,
};

export default Input;
