import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import { getCompany, deleteCompanyEmployee } from '../../api';
import Icon from '../../components/Icon/Icon';
import { showAlert, useAuth } from '../../context/authContext';
import { HTTPError } from '../../utils/http';
import DeletePrompt from '../../components/Modal/DeletePrompt/DeletePrompt';
import { Company, Employee } from '../../types';
import { moveFilteredItemsToTop } from '../../utils';

function EnrolledEmployees() {
  const [{ user }, dispatch] = useAuth();
  const [deleteEmployee, setDeleteEmployee] = React.useState<Employee>();
  const navigate = useNavigate();
  const { companyId } = useParams();
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: ['company', companyId],
    queryFn: () => getCompany(companyId),
    retry: 1,
    enabled: !!companyId,
    onError: (error: HTTPError) => {
      showAlert(
        dispatch,
        {
          show: true,
          type: 'ERROR',
          message: error.message,
        },
        4000,
      );
    },
  });

  const company = (data || {}) as Company;

  const employees = moveFilteredItemsToTop({
    array: company?.employees || [],
    findFunction: (employee: Employee) => employee?.id !== user?.id,
  });

  const { mutate: deleteEmployeeFxn, isLoading: isDeletingEmployee } =
    useMutation(
      (variables: { id: string; companyId: string }) =>
        deleteCompanyEmployee(variables.companyId, variables.id),
      {
        onSuccess: () => {
          setDeleteEmployee(undefined);
          queryClient.invalidateQueries({ queryKey: ['company', companyId] });
          showAlert(dispatch, {
            show: true,
            type: 'SUCCESS',
            message: 'Termination request submitted successfully.',
          });
        },
      },
    );

  return (
    <>
      <div className="px-16 flex justify-between items-center mobile:hidden">
        <button
          type="button"
          onClick={() => {
            navigate(-1);
          }}
          className="border borderWidth border-river-blue back-bg h-[45px] w-[45px] p-3 rounded-full 
              flex items-center justify-center"
        >
          <Icon
            className="w-4 h-4 fill-river-blue text-river-blue"
            id="arrowBack"
          />
        </button>

        <div className="text-left tablet:text-center">
          <h1 className="font-poppins font-semibold text-[30px] text-river-black capitalize">
            {company.name || '...'}
          </h1>
          <p className="text-river-black text-lg mt-2">Enrolled employees</p>
        </div>

        <span />
      </div>

      <div className="w-full px-8 tablet:px-16 pb-8 pt-10 relative">
        <table className="border-collapse table-auto w-full h-full border-b">
          <thead>
            <tr className="bg-[#F3FAFD] rounded-lg text-left sticky top-0 z-10">
              <th
                scope="col"
                className="hidden tablet:table-cell px-6 py-3 text-left text-xs font-semibold !tracking-wider 
                first:rounded-l-lg last:rounded-r-lg sticky top-0"
              >
                #
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-semibold !tracking-wider first:rounded-l-lg last:rounded-r-lg sticky top-0"
              >
                Employee name
              </th>
              <th
                scope="col"
                className="hidden tablet:table-cell px-6 py-3 text-left text-xs font-semibold 
                !tracking-wider first:rounded-l-lg last:rounded-r-lg sticky top-0"
              >
                Date of Birth
              </th>
              <th
                scope="col"
                className="hidden tablet:table-cell px-6 py-3 text-left text-xs font-semibold 
                !tracking-wider first:rounded-l-lg last:rounded-r-lg sticky top-0"
              >
                Plan
              </th>
              <th
                scope="col"
                className="hidden tablet:table-cell px-6 py-3 text-left text-xs font-semibold 
                !tracking-wider first:rounded-l-lg last:rounded-r-lg sticky top-0"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody
            className={`divide-y relative ${
              employees.length === 0 || isLoading ? 'h-[500px]' : ''
            }`}
          >
            {isLoading ? (
              <tr className="w-full h-full transition-opacity">
                <td
                  colSpan={8}
                  rowSpan={7}
                  className="!border-none w-full h-full"
                >
                  <div className="flex flex-col items-center justify-center p-10">
                    <BounceLoader color="black" size={60} />
                  </div>
                </td>
              </tr>
            ) : employees.length === 0 ? (
              <tr className="w-full h-full bg-opacity-75 transition-opacity">
                <td
                  colSpan={8}
                  rowSpan={7}
                  className="!border-none text-center"
                >
                  <span className="block text-pos-black test-sm font-medium py-10">
                    No enrolled employees found!
                  </span>
                </td>
              </tr>
            ) : (
              employees.map((findEmployee, index) => (
                <tr
                  className="text-left text-[15px] leading-[24px] tracking-[0.03em] text-river-deep-gray"
                  key={findEmployee.id}
                >
                  <td className="px-6 py-4 hidden tablet:table-cell">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      {`${findEmployee.firstName} ${findEmployee.lastName}`}
                    </div>

                    <div className="flex flex-col mt-2 tablet:hidden">
                      <div>
                        <span className="text-river-blue font-medium">
                          Date of Birth
                        </span>{' '}
                        {findEmployee.dateOfBirth || '-'}
                      </div>
                      <div>
                        <span className="text-river-blue font-medium">
                          Plan:
                        </span>{' '}
                        {findEmployee.subscriptionPlan}
                      </div>

                      <div>
                        <button
                          title={
                            findEmployee.id === user?.id
                              ? 'Removal Restricted'
                              : 'Remove Employee'
                          }
                          onClick={() =>
                            findEmployee.id === user?.id
                              ? false
                              : setDeleteEmployee(findEmployee)
                          }
                          type="button"
                          className={`text-xs mt-2 text-river-red border rounded-full px-2 border-river-red ${
                            findEmployee.id === user?.id ? 'opacity-30' : ''
                          }`}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 hidden tablet:table-cell">
                    {findEmployee.dateOfBirth || '-'}
                  </td>
                  <td className="px-6 py-4 hidden tablet:table-cell">
                    {findEmployee.subscriptionPlan}
                  </td>
                  <td className="px-6 py-4 hidden tablet:table-cell">
                    <button
                      title={
                        findEmployee.id === user?.id
                          ? 'Removal Restricted'
                          : 'Remove Employee'
                      }
                      onClick={() =>
                        findEmployee.id === user?.id
                          ? false
                          : setDeleteEmployee(findEmployee)
                      }
                      type="button"
                      disabled={findEmployee.id === user?.id}
                      className={`border rounded-full w-10 h-5 flex items-center justify-center py-3 px-3 ${
                        findEmployee.id === user?.id ? 'opacity-30' : ''
                      }`}
                    >
                      <Icon id="trash" className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <DeletePrompt
        open={Boolean(deleteEmployee)}
        deleting={isDeletingEmployee}
        onClose={() =>
          isDeletingEmployee ? false : setDeleteEmployee(undefined)
        }
        deleteText={
          deleteEmployee
            ? `${deleteEmployee.firstName} ${deleteEmployee.lastName}`
            : ''
        }
        onDelete={() =>
          deleteEmployee && companyId
            ? deleteEmployeeFxn({ id: deleteEmployee.id, companyId })
            : false
        }
      />
    </>
  );
}

export default EnrolledEmployees;
