import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import FormLayout, {
  submitForm,
} from '../../../components/FormLayout/FormLayout';
import Input from '../../../components/Inputs/Input/Input';
import { useForm, tempSaveForm } from '../../../context/formContext';
import { paths } from '../../../routes';
import { Form } from '../../../types';

const PAGE = 2;

function FormTwo() {
  const [{ form }, dispatch] = useForm();
  const navigate = useNavigate();

  const validationSchema: Yup.ObjectSchema<Partial<Record<keyof Form, any>>> =
    Yup.object().shape({
      authorizedOfficerName: Yup.string().required(),
      authorizedOfficerTitle: Yup.string().required(),
      authorizedOfficerEmail: Yup.string().required().email(),
      authorizedOfficerPhoneNumber: Yup.string()
        .test('phone', 'The phone number must be a valid US number.', value =>
          value ? isValidPhoneNumber(value) : false,
        )
        .required('Phone number is required'),
    });

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      initialValues={{
        authorizedOfficerName: form.authorizedOfficerName,
        authorizedOfficerTitle: form.authorizedOfficerTitle,
        authorizedOfficerEmail: form.authorizedOfficerEmail,
        authorizedOfficerPhoneNumber: form.authorizedOfficerPhoneNumber,
      }}
      validationSchema={validationSchema}
      onSubmit={data => {
        tempSaveForm(dispatch, { ...form, ...data });
        navigate(paths.FORM_THREE_URL_PATH);
      }}
    >
      {formik => {
        const { values, handleChange, errors } = formik;

        return (
          <FormLayout
            page={PAGE}
            previous={paths.FORM_ONE_URL_PATH}
            continueHandler={async () => {
              await submitForm(formik);
            }}
          >
            <div className="space-y-6 max-w-[720px] mx-auto px-8 pt-0 tablet:pt-7 pb-7">
              <div className="space-y-3">
                <label className="form-label" htmlFor="authorizedOfficerName">
                  What is the name of the authorized officer?
                </label>
                <Input
                  name="authorizedOfficerName"
                  id="authorizedOfficerName"
                  value={values.authorizedOfficerName}
                  onChange={handleChange}
                  placeholder="Luke Skywalker"
                  error={Boolean(errors.authorizedOfficerName)}
                />
                <p className="hintText">
                  {`The individual who will sign enrollment documents on behalf of the
                  company.`}
                </p>
              </div>
              <div className="space-y-3">
                <label className="form-label" htmlFor="authorizedOfficerTitle">
                  Title of the authorized officer
                </label>
                <Input
                  name="authorizedOfficerTitle"
                  id="authorizedOfficerTitle"
                  value={values.authorizedOfficerTitle}
                  onChange={handleChange}
                  placeholder="HR Lead"
                  error={Boolean(errors.authorizedOfficerTitle)}
                />
              </div>
              <div className="space-y-3">
                <label className="form-label" htmlFor="authorizedOfficerEmail">
                  Email of the authorized officer
                </label>
                <Input
                  name="authorizedOfficerEmail"
                  id="authorizedOfficerEmail"
                  type="email"
                  value={values.authorizedOfficerEmail}
                  onChange={handleChange}
                  placeholder="luke.skywalker@example.com"
                  error={Boolean(errors.authorizedOfficerEmail)}
                />
              </div>
              <div className="space-y-3">
                <label
                  className="form-label"
                  htmlFor="authorizedOfficerPhoneNumber"
                >
                  Phone of the authorized officer
                </label>
                <PhoneInput
                  country="US"
                  placeholder="(213)-460-5490"
                  className={`${
                    errors.authorizedOfficerPhoneNumber
                      ? '!border-river-red'
                      : ''
                  }`}
                  value={values.authorizedOfficerPhoneNumber}
                  onChange={value =>
                    formik.setFieldValue('authorizedOfficerPhoneNumber', value)
                  }
                />
              </div>
            </div>
          </FormLayout>
        );
      }}
    </Formik>
  );
}

export default FormTwo;
