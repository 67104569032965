/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { ReactComponent as imageSquigglesLeft } from '../../assets/images/image_squiggles_left.svg';
import { ReactComponent as imageSquigglesRight } from '../../assets/images/image_squiggles_right.svg';
import { ReactComponent as ImageUploadEligibilityByMail } from '../../assets/images/image_man_upload_eligibility_by_mail.svg';
import { ReactComponent as ImageModalNoticeAllDone } from '../../assets/images/image_modal_notice_all_done.svg';
import { ReactComponent as ImageIntroWelcome } from '../../assets/images/image_intro_welcome.svg';
import { ReactComponent as ImageIntroWelcomeMobile } from '../../assets/images/image_intro_welcome_mobile.svg';

type GraphicProps = {
  id: string;
  className?: string;
  onClick?: () => void;
};

const allGraphics: Record<
  string,
  (props: React.SVGProps<SVGSVGElement>) => JSX.Element | any
> = {
  graphicSquigglesLeft: imageSquigglesLeft,
  graphicSquigglesRight: imageSquigglesRight,
  graphicUploadEligibilityByMail: ImageUploadEligibilityByMail,
  graphicModalNoticeAllDone: ImageModalNoticeAllDone,
  graphicIntroWelcome: ImageIntroWelcome,
  graphicIntroWelcomeMobile: ImageIntroWelcomeMobile,
};

function Graphic({ id, ...rest }: GraphicProps) {
  const SelectedGraphic = allGraphics[id];
  return SelectedGraphic ? <SelectedGraphic id={id} {...rest} /> : null;
}

Graphic.defaultProps = {
  className: '',
  onClick: () => false,
};

export default Graphic;
