/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { Form, FormAction, FormDispatch, FormState } from '../types';
import { storage } from '../utils';

export const initialState: FormState = {
  form: {
    name: '',
    addressStreet: '',
    addressApt: '',
    addressCity: '',
    addressState: '',
    addressZipCode: '',
    addressCountry: '',
    authorizedOfficerName: '',
    authorizedOfficerTitle: '',
    authorizedOfficerEmail: '',
    authorizedOfficerPhoneNumber: '',
    employerIdentificationNumber: '',
    eligibleEmployees: NaN,
    offersStartsAt: '',
    isOfferingStarterCare: false,
    isOfferingCompleteCare: false,
    fullyCoveredStarterCare: undefined,
    fullyCoveredCompleteCare: undefined,
    amountCoveredByEmployerStarterCare: undefined,
    amountCoveredByEmployerCompleteCare: undefined,
    isRequiringPayrollIntegration: false,

    switchToHigherPlan: undefined,
    planPaymentOption: '',
    isOfferingFamilyPlan: undefined,
    amountCoveredForFamilyPlan: NaN,
  },
  hasAgreedToTerms: false,
  page: 0,
};

const FormContext = React.createContext<
  [state: FormState, dispatch: FormDispatch] | undefined
>(undefined);

FormContext.displayName = 'FormContext';

function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return {
        ...state,
        form: action.payload,
      };
    case 'SET_HAS_AGREED_TO_TERMS':
      return {
        ...state,
        hasAgreedToTerms: action.payload,
      };
    case 'SET_PAGE_NUMBER':
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}

function FormProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(
    formReducer,
    initialState,
    fndInitialState => {
      const getTempForm = JSON.parse(
        storage.get('_river_employer_temp_form') || '{}',
      );

      return Object.keys(getTempForm).length === 0
        ? fndInitialState
        : { ...fndInitialState, form: getTempForm };
    },
  );

  const value = React.useMemo(() => [state, dispatch], [state, dispatch]) as [
    state: FormState,
    dispatch: FormDispatch,
  ];

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

function useForm() {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error(`\`useForm\` must be used within a FormProvider`);
  }
  return context;
}

export function tempSaveForm(dispatch: FormDispatch, form: Form) {
  dispatch({ type: 'SET_FORM_DATA', payload: form });
  storage.set('_river_employer_temp_form', JSON.stringify(form));
}

export { FormProvider, useForm };
