/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const icons: Record<
  string,
  (props: React.SVGProps<SVGSVGElement>) => JSX.Element
> = {
  loader: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="40"
      height="40"
      viewBox="0 0 50 50"
      {...props}
    >
      <path
        fill="currentColor"
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  ),
  arrowBack: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      viewBox="0 0 21 18"
      width="21"
      height="18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.55 7.58893C19.4678 7.57558 19.3845 7.56942 19.3012 7.5705H5.11818L5.42744 7.42665C5.72974
        7.28357 6.00475 7.08885 6.24016 6.85128L10.2174 2.874C10.7413 2.37397 10.8293 1.56957 10.426
        0.968075C9.95668 0.327117 9.05662 0.187949 8.41562 0.657283C8.36383 0.695222 8.31461 0.736577
        8.26836 0.781079L1.07617 7.97326C0.514106 8.5347 0.513611 9.44545 1.07505 10.0075C1.07541 10.0079
        1.07582 10.0083 1.07617 10.0086L8.26836 17.2008C8.83087 17.7618 9.74163 17.7605 10.3026 17.198C10.3468
        17.1537 10.388 17.1066 10.426 17.057C10.8293 16.4555 10.7413 15.6511 10.2174 15.1511L6.24735
        11.1666C6.03631 10.9553 5.79366 10.7782 5.52813 10.6416L5.0966 10.4474H19.222C19.9569 10.4747
        20.6016 9.96136 20.7396 9.23908C20.8668 8.45491 20.3342 7.71614 19.55 7.58893Z"
        fill="currentColor"
      />
    </svg>
  ),
  close: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      viewBox="0 0 18 18"
      width="18"
      height="18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8218 17C15.5068 16.9979 15.2051 16.8731 14.9806 16.652L1.36526 3.03665C1.14843 2.81542 1.02649 2.51831 1.0254 2.20853C1.0243 1.89876 1.14414 1.60079 1.35941 1.37803C1.57468 1.15527 1.86837 1.02532 2.178 1.01582C2.48763 1.00632 2.78874 1.11803 3.01726 1.32717L3.04752 1.3544L16.6629 14.9698C16.8279 15.1366 16.9399 15.3485 16.985 15.5787C17.0301 15.809 17.0063 16.0474 16.9165 16.2642C16.8267 16.481 16.6749 16.6664 16.4802 16.7973C16.2854 16.9282 16.0564 16.9987 15.8218 17Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M2.18796 16.991C1.95292 16.9913 1.72307 16.9219 1.52758 16.7914C1.33209 16.6609 1.17978 16.4753 1.08998 16.2581C1.00018 16.0409 0.97694 15.8019 1.02321 15.5714C1.06948 15.341 1.18317 15.1295 1.34986 14.9638L14.9652 1.34841C15.1883 1.12533 15.4909 1 15.8064 1C16.1219 1 16.4244 1.12533 16.6475 1.34841C16.8706 1.57149 16.9959 1.87405 16.9959 2.18953C16.9959 2.50502 16.8706 2.80758 16.6475 3.03066L3.03211 16.646C2.92143 16.7568 2.78975 16.8444 2.6448 16.9036C2.49985 16.9628 2.34454 16.9925 2.18796 16.991Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  ),
  clock: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M10 1.667c-4.592 0-8.333 3.741-8.333 8.333 0 4.592 3.741 8.333 8.333 8.333 4.592 0 8.333-3.741 8.333-8.333 0-4.592-3.741-8.333-8.333-8.333Zm3.625 11.308a.622.622 0 0 1-.858.217l-2.584-1.542c-.641-.383-1.116-1.225-1.116-1.967V6.267a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v3.416c0 .3.25.742.508.892l2.583 1.542c.3.175.4.558.217.858Z"
        fill="currentColor"
      />
    </svg>
  ),
  exclamation: (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" {...props}>
      <g clipPath="url(#a)" fill="currentColor">
        <path d="M14.665 7.556a2.278 2.278 0 0 1 .463 1.89l-1.001 5.005a1.251 1.251 0 1 1-2.503 0l-1-5.005a2.276 2.276 0 0 1 .462-1.89 2.353 2.353 0 0 1 3.58 0ZM12.9 19.23a1.251 1.251 0 1 0 0-2.502 1.251 1.251 0 0 0 0 2.503Z" />
      </g>
    </svg>
  ),
};

export default icons;
