import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as RiverLogo } from '../../assets/icons/icon_river_logo.svg';
import Icon from '../Icon/Icon';

type NavbarProps = {
  page: number;
  logout: () => void;
};

function Navbar({ page, logout }: NavbarProps) {
  return (
    <div className="px-8 tablet:px-16 py-4 shadow-xl shadow-slate-50 flex justify-between items-center bg-white">
      <div>
        <Link to="/">
          <RiverLogo className="w-20 tablet:w-16" />
        </Link>
      </div>

      <div className={`flex items-baseline  ${page ? 'visible' : 'invisible'}`}>
        <div className="font-medium text-[40px] leading-[56px] tracking-[0.032em]">
          {page ?? 0}
        </div>
        <div className="text-gray-400 text-sm">/</div>
        <div className="text-gray-400 text-sm">7</div>
      </div>

      {!page && (
        <button
          onClick={logout}
          type="button"
          className="flex items-center font-medium text-sm cursor-pointer hover:opacity-80"
        >
          <Icon
            id="logoutIcon"
            className="w-5 inline-flex mr-2 text-[#676767]"
          />
          Logout
        </button>
      )}
    </div>
  );
}

export default Navbar;
