import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import { env } from '.';
import { formatError } from './http';

const reportErrorToSentry = (
  error: any,
  extra?: Record<string, unknown>,
  level?: SeverityLevel,
) => {
  if (env('RIVER_ENV') === 'development') {
    return;
  }

  const { config, message, status } = formatError(error);

  Sentry.captureException(new Error(message, { cause: error }), {
    extra: {
      ...config,
      clientMessage: message,
      clientStatus: status,
      ...extra,
    },
    ...(level && { level }),
  });
};

const reportMessageToSentry = (
  message: string,
  extra?: Record<string, unknown>,
  level?: SeverityLevel,
) => {
  if (env('RIVER_ENV') === 'development') {
    return;
  }

  Sentry.captureMessage(message, {
    extra: {
      ...extra,
    },
    ...(level && { level }),
  });
};

const reportUserIdentityToSentry = ({
  phoneNumber,
  id,
}: {
  phoneNumber: string;
  id: string;
}) => {
  Sentry.setUser({
    id,
    phone: phoneNumber,
  });
};

const clearCurrentUserOnSentry = () => {
  Sentry.setUser(null);
};

export {
  reportErrorToSentry,
  reportMessageToSentry,
  reportUserIdentityToSentry,
  clearCurrentUserOnSentry,
};
