import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import FormLayout, {
  submitForm,
} from '../../../components/FormLayout/FormLayout';
import { US_STATES } from '../../../utils';
import Input from '../../../components/Inputs/Input/Input';
import Select from '../../../components/Inputs/SelectInput/SelectInput';
import { tempSaveForm, useForm } from '../../../context/formContext';
import { paths } from '../../../routes';
import { Form } from '../../../types';

const PAGE = 1;

function FormOne() {
  const [{ form }, dispatch] = useForm();
  const navigate = useNavigate();

  const validationSchema: Yup.ObjectSchema<Partial<Record<keyof Form, any>>> =
    Yup.object().shape({
      name: Yup.string().required(),
      addressStreet: Yup.string().required(),
      addressApt: Yup.string(),
      addressCity: Yup.string().required(),
      addressState: Yup.string().required(),
      addressZipCode: Yup.string().required(),
    });

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      initialValues={{
        name: form.name,
        addressStreet: form.addressStreet,
        addressApt: form.addressApt,
        addressCity: form.addressCity,
        addressState: form.addressState,
        addressZipCode: form.addressZipCode,
      }}
      validationSchema={validationSchema}
      onSubmit={data => {
        tempSaveForm(dispatch, {
          ...form,
          ...{ ...data, name: data.name.trim() },
        });
        navigate(paths.FORM_TWO_URL_PATH);
      }}
    >
      {formik => {
        const { values, handleChange, errors } = formik;

        return (
          <FormLayout
            page={PAGE}
            continueHandler={async () => {
              await submitForm(formik);
            }}
          >
            <div className="space-y-6 max-w-[720px] mx-auto px-8 pt-0 tablet:pt-7 pb-7">
              <div className="space-y-3">
                <label className="form-label" htmlFor="name">
                  What is the name of your company?
                </label>
                <Input
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="E.g. Google Inc"
                  error={Boolean(errors.name)}
                />
                <p className="hintText">
                  {`Please enter your company's name as it appears on legal
                documents.`}
                </p>
              </div>
              <div className="space-y-3">
                <label className="form-label" htmlFor="addressStreet">
                  {`What's your company's address?`}
                </label>
                <div className="w-full space-y-4">
                  <div className="w-full">
                    <Input
                      name="addressStreet"
                      id="addressStreet"
                      value={values.addressStreet}
                      onChange={handleChange}
                      placeholder="Address 1"
                      error={Boolean(errors.addressStreet)}
                    />
                  </div>
                  <div className="grid tablet:grid-cols-2 grid-cols-1 gap-4">
                    <Input
                      name="addressApt"
                      id="addressApt"
                      value={values.addressApt}
                      onChange={handleChange}
                      placeholder="Apt/Fl"
                      error={Boolean(errors.addressApt)}
                    />
                    <Input
                      name="addressCity"
                      id="addressCity"
                      value={values.addressCity}
                      onChange={handleChange}
                      placeholder="City"
                      error={Boolean(errors.addressCity)}
                    />
                  </div>
                  <div className="grid tablet:grid-cols-2 grid-cols-1 gap-4">
                    <div className="relative">
                      <Select
                        placeholder={{
                          value: '',
                          label: 'State',
                        }}
                        value={values.addressState}
                        options={US_STATES}
                        name="addressState"
                        onChange={handleChange}
                        error={Boolean(errors.addressState)}
                      />
                    </div>
                    <Input
                      name="addressZipCode"
                      id="addressZipCode"
                      value={values.addressZipCode}
                      onChange={handleChange}
                      placeholder="Zip Code"
                      error={Boolean(errors.addressZipCode)}
                    />
                  </div>
                </div>
                <p className="hintText">
                  We may mail documents to this address.
                </p>
              </div>
            </div>
          </FormLayout>
        );
      }}
    </Formik>
  );
}

export default FormOne;
