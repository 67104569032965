import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import FormLayout, {
  submitForm,
} from '../../../components/FormLayout/FormLayout';
import Input from '../../../components/Inputs/Input/Input';
import DateInput from '../../../components/Inputs/DateInput/Date';
import { useForm, tempSaveForm } from '../../../context/formContext';
import { paths } from '../../../routes';
import { Form } from '../../../types';

const PAGE = 3;

function FormThree() {
  const [{ form }, dispatch] = useForm();
  const [dateInputType, setDateInputType] = React.useState('text');
  const navigate = useNavigate();

  const getAddedDate = (addDay: number) => {
    const today = new Date();
    const oneWeek = addDay * 24 * 60 * 60 * 1000;

    const nextWeek = new Date(today.getTime() + oneWeek);

    return nextWeek.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const validationSchema: Yup.ObjectSchema<Partial<Record<keyof Form, any>>> =
    Yup.object().shape({
      employerIdentificationNumber: Yup.string().required(),
      eligibleEmployees: Yup.number().required(),
      offersStartsAt: Yup.date()
        .min(getAddedDate(7))
        .required()
        .label('Offering start date'),
    });

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      initialValues={{
        employerIdentificationNumber: form.employerIdentificationNumber,
        eligibleEmployees: form.eligibleEmployees,
        offersStartsAt: form.offersStartsAt
          ? moment(form.offersStartsAt).format('MM/DD/YYYY')
          : '',
      }}
      validationSchema={validationSchema}
      onSubmit={data => {
        tempSaveForm(dispatch, {
          ...form,
          ...{
            ...data,
            offersStartsAt: moment(data.offersStartsAt, 'MM/DD/YYYY').format(),
          },
        });
        navigate(paths.FORM_FOUR_URL_PATH);
      }}
    >
      {formik => {
        const { values, handleChange, errors, setFieldValue } = formik;

        return (
          <FormLayout
            page={PAGE}
            previous={paths.FORM_TWO_URL_PATH}
            continueHandler={async () => {
              await submitForm(formik);
            }}
          >
            <div className="space-y-6 max-w-[720px] mx-auto px-8 pt-0 tablet:pt-7 pb-7">
              <div className="space-y-3">
                <label
                  className="form-label"
                  htmlFor="employerIdentificationNumber"
                >
                  What is your Employer Identification Number (EIN)?
                </label>
                <Input
                  name="employerIdentificationNumber"
                  id="employerIdentificationNumber"
                  value={values.employerIdentificationNumber}
                  onChange={handleChange}
                  error={Boolean(errors.employerIdentificationNumber)}
                />
              </div>

              <div className="space-y-3">
                <label className="form-label" htmlFor="eligibleEmployees">
                  How many employees will be eligible for health benefits
                  through River Health?
                </label>
                <Input
                  name="eligibleEmployees"
                  id="eligibleEmployees"
                  type="number"
                  value={
                    Number.isNaN(values.eligibleEmployees)
                      ? ''
                      : `${values.eligibleEmployees}`
                  }
                  onChange={handleChange}
                  placeholder="21"
                  onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  error={Boolean(errors.eligibleEmployees)}
                />
                <p className="hintText">An estimate is acceptable.</p>
              </div>

              <div className="space-y-3">
                <label className="form-label" htmlFor="offersStartsAt">
                  When do you want to start offering River?
                </label>
                <DateInput
                  type={dateInputType}
                  onChange={event => {
                    if (!event.target.value.match('^[0-9/]*$')) {
                      return;
                    }
                    setFieldValue(
                      'offersStartsAt',
                      event.target.value
                        .replace(/^(\d\d)(\d)$/g, '$1/$2')
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                        .replace(/[^\d/]/g, ''),
                    );
                  }}
                  value={values.offersStartsAt}
                  placeholder={getAddedDate(7)}
                  name="offersStartsAt"
                  id="offersStartsAt"
                  error={Boolean(errors.offersStartsAt)}
                  onFocus={() => setDateInputType('date')}
                  onBlur={() => {
                    if (!values.offersStartsAt) {
                      setDateInputType('text');
                    }
                  }}
                />
                <p className="hintText">
                  This is the date employees can start using their benefits.
                  Enrollment emails go out one (1) week before the start date.
                </p>
              </div>
            </div>
          </FormLayout>
        );
      }}
    </Formik>
  );
}

export default FormThree;
