import * as React from 'react';

type AlertProps = {
  show: boolean;
  type: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO';
  message: string;
  onAction?: () => void;
  onActionMessage?: string;
};

function Alert({ show, type, message, onActionMessage, onAction }: AlertProps) {
  const alertRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (show) {
      alertRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [show]);

  return (
    <div
      ref={alertRef}
      className={`alert ${show ? 'show' : ''} alert--${type} `}
    >
      {show && (
        <div className="alert-message">
          {message}{' '}
          {onAction && (
            <button
              type="button"
              className="alert-action underline"
              onClick={onAction}
            >
              {onActionMessage}
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default Alert;
