/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import { useMutation } from '@tanstack/react-query';
import { useMergeLink } from '@mergeapi/react-merge-link';
import FormLayout from '../../../components/FormLayout/FormLayout';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import {
  useForm,
  tempSaveForm,
  initialState,
} from '../../../context/formContext';
import { paths } from '../../../routes';
import { showAlert, useAuth } from '../../../context/authContext';
import { HTTPError } from '../../../utils/http';
import {
  createCompany,
  getMergeLinkToken,
  sendTokenToMerge,
} from '../../../api';
import { Form } from '../../../types';

const PAGE = 7;

function FormSeven() {
  const [, authDispatch] = useAuth();
  const [{ form }, dispatch] = useForm();
  const [mergeLinkedToken, setMergeLinkedToken] = React.useState('');
  const [isOpeningMerge, setIsOpeningMerge] = React.useState(false);
  const navigate = useNavigate();

  const onSuccess = React.useCallback((publicToken: string) => {
    (async () => {
      sendTokenToMergeFn(publicToken);
    })();
  }, []);

  const { open, isReady } = useMergeLink({
    linkToken: mergeLinkedToken,
    onSuccess,
  });

  const { mutate: createCompanyFn, isLoading } = useMutation(
    (variables: Form) => createCompany(variables),
    {
      onSuccess: (data, variables) => {
        // reset temp save form data.
        tempSaveForm(dispatch, initialState.form);

        if (variables.isRequiringPayrollIntegration) {
          dispatch({ type: 'SET_PAGE_NUMBER', payload: 0 });
          navigate(paths.HOME_URL_PATH);
        } else {
          navigate(paths.SUMMARY_MANUAL_URL_PATH);
        }
      },
      onError: (error: HTTPError) => {
        showAlert(authDispatch, {
          show: true,
          type: 'ERROR',
          message: error.message,
        });
      },
    },
  );

  const { mutate: sendTokenToMergeFn, isLoading: isSendingMergeToken } =
    useMutation((publicToken: string) => sendTokenToMerge(publicToken), {
      onSuccess: () => {
        handleCreateCompany(true);
      },
    });

  const { mutate: getMergeLinkTokenFn, isLoading: isGeneratingMergeToken } =
    useMutation(
      (variables: { email: string; organizationName: string }) =>
        getMergeLinkToken(variables.email, variables.organizationName),
      {
        onSuccess: data => {
          setIsOpeningMerge(true);
          setMergeLinkedToken(data.token);
          if (isReady) {
            setIsOpeningMerge(false);
            open();
          }
        },
      },
    );

  const handleCreateCompany = (doPayroll: boolean) => {
    const fndForm = { ...form, isRequiringPayrollIntegration: doPayroll };
    tempSaveForm(dispatch, fndForm);
    createCompanyFn(fndForm);
  };

  return (
    <FormLayout page={PAGE} previous={paths.FORM_SIX_URL_PATH}>
      <div className="flex flex-col items-center text-center px-8 pt-0 tablet:pt-7 pb-7">
        <h3 className="font-semibold text-[20px] tablet:text-xl text-river-black mb-4 tablet:mb-4">
          Eligibility Files
        </h3>
        <p className="laptop:w-1/2 w-full mx-auto mb-10 tablet:mb-16">
          You can share a list of eligible employees by connecting your payroll
          to River Health or emailing a list to{' '}
          <a
            href="mailto:team@helloriver.com"
            className="underline underline-offset-2"
          >
            team@helloriver.com
          </a>
        </p>

        <div className="grid gap-y-10 tablet:grid-cols-2 tablet:gap-x-7 tablet:gap-y-0 laptop:w-[50%] w-full">
          <div
            className="flex flex-col bg-white border border-[#B6B6B6] text-center
            px-6 w-full rounded-2xl py-8 text-river-black items-center relative"
          >
            {isLoading && (
              <div
                className="absolute bg-black/[0.6] w-full h-full top-0 left-0 flex items-center 
                justify-center rounded-xl"
              >
                <BounceLoader color="white" size={60} />
              </div>
            )}

            <div className="flex flex-col items-center w-3/5 mx-auto">
              <Icon id="eligibilityByEmail" className="w-[140px]" />
              <p className="text-sm font-medium">
                I will send a list via email.
              </p>
            </div>

            <div className="flex-1 flex items-end justify-center mt-14">
              <Button
                label={!isLoading ? 'Select' : 'Please wait...'}
                disabled={isLoading || isGeneratingMergeToken}
                onClick={() => handleCreateCompany(false)}
                className="w-[225px]"
              />
            </div>
          </div>

          <div
            className="flex flex-col bg-white border border-[#B6B6B6] text-center
            px-6 w-full rounded-2xl py-8 text-river-black items-center relative"
          >
            {(isGeneratingMergeToken ||
              isOpeningMerge ||
              isSendingMergeToken) && (
              <div
                className="absolute bg-black/[0.6] w-full h-full top-0 left-0 flex items-center 
                justify-center rounded-xl"
              >
                <BounceLoader color="white" size={60} />
              </div>
            )}

            <div className="flex flex-col items-center w-3/5 mx-auto">
              <Icon id="eligibilityByPayroll" className="w-[140px]" />
              <p className="text-sm font-medium">I will connect my payroll.</p>
            </div>

            <div className="flex-1 flex items-end justify-center mt-14">
              <Button
                label={!isGeneratingMergeToken ? 'Select' : 'Please wait...'}
                onClick={() =>
                  getMergeLinkTokenFn({
                    email: form.authorizedOfficerEmail,
                    organizationName: form.name,
                  })
                }
                disabled={isGeneratingMergeToken || isLoading}
                className="w-[225px]"
              />
            </div>
          </div>
        </div>
      </div>
    </FormLayout>
  );
}

export default FormSeven;
